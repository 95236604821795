
import axios from 'axios'
import authHeader from './auth-header'

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class StudentService {
    index() {
        return axios.get(VUE_APP_BASE_URL+'student', {headers: authHeader()});
    }
    indexById(id, page) {
        return axios.get(VUE_APP_BASE_URL+'student-byid/'+id+'?page='+page, {headers: authHeader()});
    }
    upload(formData, config) {
        return axios.post(VUE_APP_BASE_URL+'student-upload', formData, config);
    }
    byPage(page) {
        return axios.get(VUE_APP_BASE_URL+'student?page='+page, {headers: authHeader()});
    }
    search(search_term) {
        return axios.get(VUE_APP_BASE_URL+'student-search/'+search_term, {headers: authHeader()});
    }
    promote(){
        return axios.get(VUE_APP_BASE_URL+'student-promote', {headers: authHeader()});
    }
    class(class_id) {
        return axios.get(VUE_APP_BASE_URL+'student-class/'+class_id, {headers: authHeader()});
    }
    store(formData) {
        return axios.post(VUE_APP_BASE_URL+'student', formData, {headers: authHeader() });
    }
    update(formData) {
        return axios.put(VUE_APP_BASE_URL+'student/'+formData.id, formData, {headers: authHeader() });
    }
    show(id) {
        return axios.get(VUE_APP_BASE_URL+'student/'+id, {headers: authHeader()});
    }
    delete(id) {
        return axios.delete(VUE_APP_BASE_URL+'student/'+id, {headers: authHeader()});
    }
    previewStatement(id) {
        return axios.get(VUE_APP_BASE_URL+'student-statement/'+id, {headers: authHeader()});
    }
    link(formData) {
        return axios.post(VUE_APP_BASE_URL+'student-link-guardian',formData, {headers: authHeader()});
    }
    detach(formData) {
        return axios.post(VUE_APP_BASE_URL+'student-detach-guardian',formData, {headers: authHeader()});
    }
    attachFee(formData){
        return axios.post(VUE_APP_BASE_URL+'student-attach-fee',formData, {headers: authHeader()});
    }
    
}

export default new StudentService()
