<template>
  <div>
    <div class="row mb-2">
      <div class="col">
        <div class="card bg-100 shadow-none border">
          <div class="row gx-0 flex-between-center">
            <div class="col-sm-auto d-flex align-items-center">
              <img
                class="ms-n2"
                src="../assets/img/illustrations/crm-bar-chart.png"
                alt=""
                width="90"
              />
              <div class="">
                <h5 class="text-primary fs--1 mb-0 mt-2">
                  {{ getTimegreetings() }}, {{ user.first_name }},
                </h5>
                <!-- <h6 class="text-primary fs--1 mb-0">Welcome to</h6> -->
                <div class="d-flex">
                  <span class="text-600 fs--1 d-flex pt-2"> Welcome to </span>
                  <span class="text-primary fw-bold mb-0 pt-1 ms-2">
                    <span class="text-info fw-medium">EduSpace</span> Admin
                    Portal
                  </span>
                </div>
              </div>
              <img
                class="ms-n4 d-md-none d-lg-block"
                src="../assets/img/illustrations/crm-line-chart.png"
                alt=""
                width="150"
              />
            </div>
            <div class="col-md-auto p-3">
              <form class="row align-items-center g-2">
                <img
                  class="ms-n4 d-md-none d-lg-block"
                  src="/assets/img/admin-message.png"
                  alt=""
                  style="width: 162px"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row g-2 mb-2">
      <div class="col-12 col-xl-12">
        <div class="row g-3">
          <div class="col-lg-12">
            <div class="row g-3 mb-2">
              <div class="col-sm-3">
                <div class="card overflow-hidden" style="min-width: 12rem">
                  <div
                    class="bg-holder bg-card"
                    style="
                      background-image: url('../assets/img/icons/spot-illustrations/corner-1.png');
                    "
                  ></div>
                  <div
                    class="card-body position-relative"
                    :key="daily_analysis.customers"
                  >
                    <h6>
                      Students<span
                        class="badge badge-soft-warning rounded-pill ms-2"
                        >This year</span
                      >
                    </h6>
                    <div class="d-flex">
                      <count-up
                        class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-warning"
                        :end-val="daily_analysis.customers"
                        :start-val="0"
                      ></count-up>
                      <vue-feather
                        class="ms-auto"
                        size="32"
                        type="users"
                      ></vue-feather>
                    </div>
                    <a
                      class="fw-semi-bold fs--1 text-nowrap"
                      @click="goToCustomers"
                      >See all<span
                        class="fas fa-angle-right ms-1"
                        data-fa-transform="down-1"
                      ></span
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="card overflow-hidden" style="min-width: 12rem">
                  <div
                    class="bg-holder bg-card"
                    style="
                      background-image: url('../assets/img/icons/spot-illustrations/corner-2.png');
                    "
                  ></div>
                  <div class="card-body position-relative">
                    <h6>Revenue</h6>
                    <div class="d-flex">
                      <count-up
                        class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-info"
                        :end-val="daily_analysis.closed_orders"
                        :start-val="0"
                      ></count-up>
                      <vue-feather
                        class="ms-auto"
                        size="32"
                        type="briefcase"
                      ></vue-feather>
                    </div>
                    <a
                      class="fw-semi-bold fs--1 text-nowrap"
                      href="../app/e-commerce/orders/order-list.html"
                      >This month<span
                        class="fas fa-angle-right ms-1"
                        data-fa-transform="down-1"
                      ></span
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="card overflow-hidden" style="min-width: 12rem">
                  <div
                    class="bg-holder bg-card"
                    style="
                      background-image: url('../assets/img/icons/spot-illustrations/corner-1.png');
                    "
                  ></div>
                  <div
                    class="card-body position-relative"
                    :key="daily_analysis.customers"
                  >
                    <h6>
                      Revenue<span
                        class="badge badge-soft-warning rounded-pill ms-2"
                        >This year</span
                      >
                    </h6>
                    <div class="d-flex">
                      <count-up
                        class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-success"
                        :end-val="daily_analysis.customers"
                        :start-val="0"
                      ></count-up>
                      <vue-feather
                        class="ms-auto"
                        size="32"
                        type="refresh-ccw"
                      ></vue-feather>
                    </div>
                    <a
                      class="fw-semi-bold fs--1 text-nowrap"
                      @click="goToCustomers"
                      >This month<span
                        class="fas fa-angle-right ms-1"
                        data-fa-transform="down-1"
                      ></span
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="card overflow-hidden" style="min-width: 12rem">
                  <div
                    class="bg-holder bg-card"
                    style="
                      background-image: url('../assets/img/icons/spot-illustrations/corner-2.png');
                    "
                  ></div>
                  <div class="card-body position-relative">
                    <h6>
                      Total Profit<span
                        class="badge badge-soft-info rounded-pill ms-2"
                        >{{
                          daily_analysis.total_orders > 0
                            ? (daily_analysis.closed_orders /
                                daily_analysis.total_orders) *
                              100
                            : 0
                        }}%</span
                      >
                    </h6>
                    <div class="d-flex">
                      <count-up
                        class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-info"
                        :end-val="daily_analysis.closed_orders"
                        :start-val="0"
                      ></count-up>
                      <vue-feather
                        class="ms-auto"
                        size="32"
                        type="pocket"
                      ></vue-feather>
                    </div>
                    <a
                      class="fw-semi-bold fs--1 text-nowrap"
                      href="../app/e-commerce/orders/order-list.html"
                      >This month<span
                        class="fas fa-angle-right ms-1"
                        data-fa-transform="down-1"
                      ></span
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row g-3 mb-3">
      <div class="col-12">
        <div class="card">
          <div class="card-body py-3">
            <h6 class="px-1 text-700 me-2 mb-2">Quick Links</h6>
            <span class="ms-auto">
              <button
                class="btn btn-sm btn-outline-info me-1 py-2"
                @click="goToAdmission"
              >
                Add Student 
                <!-- <i class="fa fa-file-code"></i> -->
              </button>
              <button
                type="button"
                class="btn btn-sm btn-outline-warning me-2 py-2"
                @click="$router.push('/scores')"
              >
                Scores
                <!-- <span class="badge rounded-pill ms-1 badge-soft-info">All</span> -->
              </button>
              <button
                type="button"
                class="btn btn-sm btn-outline-danger me-2 py-2"
                disabled
              >
                Payments
                <!-- <span class="badge rounded-pill ms-1 badge-soft-info">All</span> -->
              </button>
              <button
                type="button"
                class="btn btn-sm btn-outline-primary me-2 py-2"
                disabled
              >
                Add Expenses
                <!-- <span class="badge rounded-pill ms-1 badge-soft-primary"
                  >All</span
                > -->
              </button>
              <button
                type="button"
                class="btn btn-sm btn-outline-success me-2 py-2"
                disabled
              >
                Transacripts
                <span class="badge rounded-pill ms-1 badge-soft-success"
                  >All</span
                >
              </button>
              <button
                type="button"
                class="btn btn-sm btn-outline-warning me-2 py-2"
                @click="$router.push('/scores')"
              >
                Add Scores
                <!-- <span class="badge rounded-pill ms-1 badge-soft-info">All</span> -->
              </button>
              <button
                class="btn btn-sm btn-outline-info me-1 py-2"
                @click="goToAdmission"
              >
                Student IDs <i class="fa fa-credit-card"></i>
              </button>
              <button
                class="btn btn-sm btn-outline-danger me-1 py-2"
                @click="goToAdmission"
              >
                Student IDs
                 <!-- <i class="fa fa-file-code"></i> -->
              </button>
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card h-md-100 h-100">
          <div class="card-body" :key="chart_key">
            <div class="row h-100 justify-content-between g-0">
              <h6 class="mt-1">Income Analysis</h6>
              <hr />
              <div class="fs--2 mt-3" v-if="weekly_analysis">
                <vue-echarts
                  :option="income_expense_graph_options"
                  style="height: 350px; width: 100%"
                  ref="market-share-chart"
                  id="market-share-chart"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="card h-md-100 h-100">
          <div class="card-body" :key="chart_key">
            <div class="row h-100 justify-content-between g-0">
              <h6 class="mt-1">Estimated Fee this Month</h6>
              <hr />
              <div class="fs--2 mt-3" v-if="weekly_analysis">
                <vue-echarts
                  :option="monthly_estimated_fee_option"
                  style="height: 350px; width: 100%"
                  ref="market-share-chart"
                  id="market-share-chart"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card h-md-100 h-100">
          <div class="card-body" :key="chart_key">
            <div class="row h-100 justify-content-between g-0">
              <h6 class="mt-1">Student Statistics</h6>
              <hr>
              <div class="fs--2 mt-3" v-if="weekly_analysis">
                <vue-echarts
                  :option="student_statistics"
                  style="height: 350px; width: 100%"
                  ref="chart"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card h-md-100 h-100">
          <div class="card-body" :key="chart_key">
            <div class="row h-100 justify-content-between g-0">
              <h6 class="mt-1">Fee Payment Analysis</h6>
              <hr>
              <div class="fs--2 mt-3" v-if="weekly_analysis">
                <vue-echarts
                  :option="fee_payment_option"
                  style="height: 350px; width: 100%"
                  ref="chart"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="authentication-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="authentication-modal-label"
      aria-hidden="true"
    >
      <div class="modal-dialog mt-6" role="document">
        <div class="modal-content border-0">
          <div
            class="modal-header px-5 position-relative modal-shape-header bg-shape"
          >
            <div class="position-relative z-index-1 light">
              <h4 class="mb-0 text-white" id="authentication-modal-label">
                Register
              </h4>
              <p class="fs--1 mb-0 text-white">
                Please create your free Falcon account
              </p>
            </div>
            <button
              class="btn-close btn-close-white position-absolute top-0 end-0 mt-2 me-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body py-4 px-5">
            <form>
              <div class="mb-3">
                <label class="form-label" for="modal-auth-name">Name</label
                ><input
                  class="form-control"
                  type="text"
                  autocomplete="on"
                  id="modal-auth-name"
                />
              </div>
              <div class="mb-3">
                <label class="form-label" for="modal-auth-email"
                  >Email address</label
                ><input
                  class="form-control"
                  type="email"
                  autocomplete="on"
                  id="modal-auth-email"
                />
              </div>
              <div class="row gx-2">
                <div class="mb-3 col-sm-6">
                  <label class="form-label" for="modal-auth-password"
                    >Password</label
                  ><input
                    class="form-control"
                    type="password"
                    autocomplete="on"
                    id="modal-auth-password"
                  />
                </div>
                <div class="mb-3 col-sm-6">
                  <label class="form-label" for="modal-auth-confirm-password"
                    >Confirm Password</label
                  ><input
                    class="form-control"
                    type="password"
                    autocomplete="on"
                    id="modal-auth-confirm-password"
                  />
                </div>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="modal-auth-register-checkbox"
                /><label class="form-label" for="modal-auth-register-checkbox"
                  >I accept the <a href="index.html#!">terms </a>and
                  <a href="index.html#!">privacy policy</a></label
                >
              </div>
              <div class="mb-3">
                <button
                  class="btn btn-primary d-block w-100 mt-3"
                  type="submit"
                  name="submit"
                >
                  Register
                </button>
              </div>
            </form>
            <div class="position-relative mt-5">
              <hr />
              <div class="divider-content-center">or register with</div>
            </div>
            <div class="row g-2 mt-2">
              <div class="col-sm-6">
                <a
                  class="btn btn-outline-google-plus btn-sm d-block w-100"
                  href="index.html#"
                  ><span
                    class="fab fa-google-plus-g me-2"
                    data-fa-transform="grow-8"
                  ></span>
                  google</a
                >
              </div>
              <div class="col-sm-6">
                <a
                  class="btn btn-outline-facebook btn-sm d-block w-100"
                  href="index.html#"
                  ><span
                    class="fab fa-facebook-square me-2"
                    data-fa-transform="grow-8"
                  ></span>
                  facebook</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Quotation modal  -->
    <div
      class="modal fade"
      id="quotationpreviewModal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="false"
    >
      <div class="modal-dialog modal-xl mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">Quotation Preview</h4>
              <p class="fs--2 mb-0">
                Added by
                <a class="link-600 fw-semi-bold" href="#!"
                  >{{ user.first_name }} {{ user.last_name }}</a
                >
              </p>
            </div>
            <div class="p-4">
              <div class="row">
                <div class="col-lg-12">
                  <iframe
                    :key="iframe_key"
                    :src="iframe_route"
                    frameborder="0"
                    width="100%"
                    height="500px"
                    class="mt-n3"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-background-color: #edf2f9;
  --easy-table-header-item-padding: 10px 15px;
}
</style>
<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import mixins from "../mixins/index";
import CountUp from "vue-countup-v3";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import AppBar from "@/components/AppBar.vue";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import ReportService from "../services/report.service";
import ProviderService from "../services/provider.service";
import EasyDataTable from "vue3-easy-data-table";
import SimpleTypeahead from "vue3-simple-typeahead";
import { useVuelidate } from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import "vue3-simple-typeahead/dist/vue3-simple-typeahead.css"; //Optional default CSS

// echarts
import { VueEcharts } from "vue3-echarts";

const colors = ["#5470C6", "#EE6666"];

export default {
  mixins: [mixins],
  components: {
    AppBar,
    CountUp,
    NavDrawer,
    VueEcharts,
    flatPickr,
    FooterComp,
    EasyDataTable,
    SimpleTypeahead,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      date: new Date(),
      iframe_route: "",
      iframe_key: "",
      refreshed: 0,
      providers: [],
      daily_analysis: [],
      weekly_analysis: [],
      headers: [
        { text: "Provider", value: "name", sortable: true },
        { text: "Type", value: "type", sortable: true },
        {
          text: "Total (Ksh)",
          value: "amount",
          sortable: true,
          width: 120,
        },
      ],
      formData: {
        car_value: "",
        insurance_type: "MPC",
      },
      year: new Date().getFullYear(),
      last_15_years: this.generateLastYears(15),
      car_makes: [
        "Abarth",
        "Acura",
        "Alfa",
        "Aston",
        "Audi",
        "Bentley",
        "BMW",
        "Buick",
        "Cadillac",
        "Chevrolet",
        "Chrysler",
        "Citroen",
        "Dacia",
        "Ferrari",
        "Fiat",
        "Ford",
        "GMC",
        "Honda",
        "Hummer",
        "Hyundai",
        "Infiniti",
        "Isuzu",
        "Jaguar",
        "Jeep",
        "Kia",
        "Lamborghini",
        "Lancia",
        "Land",
        "Lexus",
        "Lincoln",
        "Lotus",
        "Maserati",
        "Mazda",
        "Mercedes",
        "Mercury",
        "Mini",
        "Mitsubishi",
        "Nissan",
        "Opel",
        "Peugeot",
        "Pontiac",
        "Porsche",
        "Ram",
        "Renault",
        "Saab",
        "Saturn",
        "Scion",
        "Seat",
        "Skoda",
        "Smart",
        "SsangYong",
        "Subaru",
        "Suzuki",
        "Tesla",
        "Toyota",
        "Volkswagen",
        "Volvo",
        "Wiesmann",
      ],
      income_expense_graph_options: {
        color: colors,
        tooltip: {
          trigger: "none",
          axisPointer: {
            type: "cross",
          },
        },
        legend: {},
        grid: {
          top: 70,
          bottom: 50,
        },
        xAxis: [
          {
            type: "category",
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              onZero: false,
              lineStyle: {
                color: colors[1],
              },
            },
            axisPointer: {
              label: {
                formatter: function (params) {
                  return (
                    "Expense  " +
                    params.value +
                    (params.seriesData.length
                      ? "：" + params.seriesData[0].data
                      : "")
                  );
                },
              },
            },
            // prettier-ignore
            data: ['2023-1', '2023-2', '2023-3', '2023-4', '2023-5', '2023-6', '2023-7', '2023-8', '2023-9', '2023-10', '2023-11', '2023-12'],
          },
          {
            type: "category",
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              onZero: false,
              lineStyle: {
                color: colors[0],
              },
            },
            axisPointer: {
              label: {
                formatter: function (params) {
                  return (
                    "Revenue " +
                    params.value +
                    (params.seriesData.length
                      ? "：" + params.seriesData[0].data
                      : "")
                  );
                },
              },
            },
            // prettier-ignore
            data: ['2023-1', '2023-2', '2023-3', '2023-4', '2023-5', '2023-6', '2023-7', '2023-8', '2023-9', '2023-10', '2023-11', '2023-12'],
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "Renue (2023)",
            type: "line",
            xAxisIndex: 1,
            smooth: true,
            emphasis: {
              focus: "series",
            },
            data: [
              2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0,
              2.3,
            ],
          },
          {
            name: "Expense (2023)",
            type: "line",
            smooth: true,
            emphasis: {
              focus: "series",
            },
            data: [
              3.9, 5.9, 11.1, 18.7, 48.3, 69.2, 231.6, 46.6, 55.4, 18.4, 10.3,
              0.7,
            ],
          },
        ],
      },
      monthly_estimated_fee_option: {
  tooltip: {
    trigger: 'item'
  },
  legend: {
    orient: 'horizontal',
    left: 'left'
  },
  series: [
    {
      name: 'Totals in (KES)',
      type: 'pie',
      radius: '75%',
      data: [
        { value: 735, name: 'Remainings' },
        { value: 1048, name: 'Collections' },
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
      },
      student_statistics: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // Use axis to trigger tooltip
            type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
          },
        },
        legend: {},
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
        },
        yAxis: {
          type: "category",
          data: ["Form1", "Form2", "Form3", "Form4", "Fri", "Sat", "Sun"],
        },
        series: [
          {
            name: "Male",
            type: "bar",
            stack: "total",
            label: {
              show: true,
            },
            emphasis: {
              focus: "series",
            },
            data: [120, 132, 101, 134, 90, 230, 210],
          },
          {
            name: "Female",
            type: "bar",
            stack: "total",
            label: {
              show: true,
            },
            emphasis: {
              focus: "series",
            },
            data: [220, 182, 191, 234, 290, 330, 310],
          },
        ],
      },
      fee_payment_option: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: "category",
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [120, 200, 150, 80, 70, 110, 130],
            type: "bar",
          },
        ],
      },
    };
  },
  validations() {
    return {
      formData: {
        customer_name: { required },
        car_value: { required },
        courtesy_car: { required },
        excess_protector: { required },
        pvt: { required },
        yom: { required },
        reg_no: { required },
        phone: { required, minLength: minLength(8) },
        email: { required, email },
      },
    };
  },
  created() {
    // this.forceReload();
    this.getWeeklyAnalysis();
    this.getDailyAnalysis();
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
  },
  methods: {
    goToSample() {
      this.$router.push("/quotation-sample");
    },
    goToAdmission() {
      this.$router.push("/new-admission");
    },
    phoneUpdated(e) {
      this.v$.formData.phone.$touch();
      console.log(e.target.value);
      this.formData.phone = e.target.value;
    },
    customer_nameUpdated(e) {
      this.v$.formData.customer_name.$touch();
    },
    reg_noUpdated() {
      this.v$.formData.reg_no.$touch();
    },
    car_valueUpdated() {
      this.v$.formData.car_value.$touch();
    },
    emailUpdated() {
      this.v$.formData.email.$touch();
    },
    insurance_typeUpdated() {
      this.v$.formData.insurance_type.$touch();
    },
    makeUpdated(make) {
      console.log(make);
      this.formData.car_make = make;
      this.v$.formData.car_make.$touch();
    },
    async showRow(item) {
      let data = {
        id: item.id,
        form_data: this.formData,
        premium_provider: item,
      };
      console.log("item", item);
      console.log(data);
      const encoded = await window.btoa(JSON.stringify(data));
      this.iframe_route =
        process.env.VUE_APP_ROOT_URL + "#/quotation/" + encoded;
      this.iframe_key = encoded;
      var myModal = new bootstrap.Modal(
        document.getElementById("quotationpreviewModal")
      );
      myModal.show();

      // $("#quotationpreviewModal").show();
      // window.open(route.href);
    },
    yearSelected(e) {
      this.formData.yom = e.target.value;
      this.getQuotations();
    },
    goToCustomers() {},
    getTimegreetings() {
      const time = new Date().getHours();
      let greetings;
      if (time < 12) greetings = "Good Morning";
      else if (time >= 12 && time <= 17) greetings = "Good Afternoon";
      else greetings = "Good Evening";
      return greetings;
    },

    getQuotations() {
      this.v$.formData.car_value.$touch();
      this.v$.formData.yom.$touch();
      this.v$.formData.reg_no.$touch();
      this.v$.formData.phone.$touch();
      this.v$.formData.email.$touch();
      console.group("Valid");
      if (this.formData.car_value > 5000000) {
        toast.error("Please gnerate a custom quote for values above 5M!", {
          autoClose: 6000,
        });
      }
      if (this.v$.formData.$errors.length > 0) {
        return;
      }
      this.$store.commit("SET_LOADING", true);
      return ProviderService.quote(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.providers = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
          toast.success("Horaay! Quotation generated successfully!");
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          toast.error("Snap! A server error occurred!");
        }
      );
    },
    getDailyAnalysis() {
      this.$store.commit("SET_LOADING", true);
      return ReportService.dailyAnalysis().then(
        (response) => {
          if (response.data.code == 200) {
            this.daily_analysis = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getWeeklyAnalysis() {
      this.$store.commit("SET_LOADING", true);
      return ReportService.weeklyAnalysis().then(
        (response) => {
          if (response.data.code == 200) {
            this.weekly_analysis = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
