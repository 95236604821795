
import axios from 'axios'
import authHeader from './auth-header'

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class FormsService {
    index() {
        return axios.get(VUE_APP_BASE_URL+'subject', {headers: authHeader()});
    }
    all() {
        return axios.get(VUE_APP_BASE_URL+'subject-all', {headers: authHeader()});
    }
    byPage(page){
        return axios.get(VUE_APP_BASE_URL+'subject?page='+page, {headers: authHeader()});
    }
    store(formData) {
        return axios.post(VUE_APP_BASE_URL+'subject', formData, {headers: authHeader() });
    }
    update(formData) {
        return axios.put(VUE_APP_BASE_URL+'subject/'+formData.id, formData, {headers: authHeader() });
    }
    delete(id) {
        return axios.delete(VUE_APP_BASE_URL+'subject/'+id, {headers: authHeader()});
    }
}

export default new FormsService()
