<template>
  <div>
    <!-- ===============================================-->
    <!--    Main Content-->
    <!-- ===============================================-->
    <main class="main" id="top">
      <div class="container" data-layout="container">
        <div class="row flex-center min-vh-100 py-6">
          <div class="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
            <div class="card">
              <div class="card-header">
                <a
                  class="d-flex flex-center mt-2"
                  href="https://www.foody.bulla.co.ke/"
                >
                  <img
                    class="ms-2"
                    src="assets/img/EduSpace.png"
                    alt=""
                    height="54"
                  />
                </a>
                <span class="d-flex flex-center mt-1 text-muted">Management made a breeze</span>
                <!-- <span class="d-flex flex-center mt-1 text-muted">Making lunchtime a breeze</span> -->
              </div>

              <div class="position-relative">
                <hr />
                <div class="divider-content-center">
                  <h6 class="text-primary">Sign in</h6>
                </div>
              </div>
              <div class="card-body">
                <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon1">
                    <div class="btn-group">
                      <div class="dropdown font-sans-serif d-inline-block">
                        <a
                          class="dropdown-toggle"
                          :class="username_focused ? ' text-warning' : 'text-secondary'"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="far fa-question-circle mt-1"></i>
                        </a>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <div
                            class="scrollbar list"
                            style="max-height: 250px; width: 270px"
                          >
                            <h6
                              class="
                                dropdown-header
                                fw-bold
                                text-uppercase
                                px-card
                                fs--2
                                pt-0
                                pb-2
                              "
                            >
                              Select users
                            </h6>
                            <div class="dropdown-divider"></div>
                            <a
                              v-for="(user, i) in users"
                              :key="i"
                              class="dropdown-item"
                              data-toggle="dropdown"
                              @click="setUser(user.email)"
                              >{{ user.first_name }} {{ user.last_name }}</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                  <input
                    class="form-control"
                    type="email"
                    v-model="formData.email"
                    placeholder="Email/Username"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    :class="
                      v$.formData.email.$dirty && v$.formData.email.$invalid
                        ? `is-invalid`
                        : v$.formData.email.$dirty
                        ? `is-valid`
                        : ``
                    "
                    @focus="usernameFocus(true)"
                    @blur="usernameFocus(false)"
                  />
                  <div
                    v-for="error of v$.formData.email.$errors"
                    :key="error.$uid"
                    class="invalid-feedback"
                    style="color: red"
                  >
                    Oops! The email address is invalid.
                  </div>
                </div>
                <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon1"
                          :class="password_focused ? 'text-warning' : 'text-secondary'">
                    <i class="fas fa-unlock-alt me-2 ms-2"></i>
                  </span>
                  <input
                    v-model="formData.password"
                    class="form-control input-lg"
                    type="password"
                    placeholder="Password"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    @focus="passwordFocus(true)"
                    @blur="passwordFocus(false)"
                    v-on:keydown.enter="handleLogin"
                    :class="
                      v$.formData.password.$dirty &&
                      v$.formData.password.$invalid
                        ? `is-invalid`
                        : v$.formData.password.$dirty
                        ? `is-valid`
                        : ``
                    "
                  />
                  <div
                    v-for="error of v$.formData.password.$errors"
                    :key="error.$uid"
                    class="invalid-feedback"
                    style="color: red"
                  >
                    Oops! The email address is invalid.
                  </div>
                </div>
                <div class="row flex-between-center">
                  <div class="col-auto">
                    <div class="form-check mb-0">
                      <input
                        v-model="formData.remember_me"
                        class="form-check-input"
                        type="checkbox"
                        id="basic-checkbox"
                        checked="checked"
                      /><label
                        class="form-check-label mb-0"
                        for="basic-checkbox"
                        >Remember me</label
                      >
                    </div>
                  </div>
                  <div class="col-auto">
                    <a role="button" class="fs--1" @click="gotToForgotPassword"
                      >Forgot Password?</a
                    >
                  </div>
                </div>
                <div class="mb-3">
                  <button
                    class="btn btn-primary d-block w-100 mt-3"
                    @click="handleLogin"
                  >
                    Log in
                  </button>
                </div>
                <div class="position-relative mt-4">
                  <hr />
                  <div class="divider-content-center">
                    <span class="font-sans-serif d-flex flex-center"
                      >Bullla Foody |
                      <a class="mb-0 text-500">Version {{ version }}</a></span
                    >
                    <!-- <span class="mb-0 undefined">or</span>
                    <span><a href="register.html">Create an account</a></span> -->
                  </div>
                </div>
                <div class="row g-2 mt-4">
                  <small class="text-muted d-flex flex-center mt-2">
                    {{ year }} &copy; Bulla Softworks Ltd</small
                  >
                  <a
                    class="d-flex flex-center mt-n1"
                    href="https://bulla.co.ke"
                  >
                    <img
                      class="me-2"
                      src="assets/img/icons/spot-illustrations/bulla.png"
                      alt=""
                      height="32"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- ===============================================-->
    <!--    End of Main Content-->
    <!-- ===============================================-->
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useVuelidate } from "@vuelidate/core";
import UserService from "../services/user.service";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import { required, email, minLength } from "@vuelidate/validators";

export default {
  name: "LoginView",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    LoaderOverlay,
  },
  data() {
    return {
      username_focused: false,
      password_focused: false,
      year: new Date().getFullYear(),
      formData: {},
      users: [],
      version: process.env.VUE_APP_VERSION,
    };
  },
  validations() {
    return {
      formData: {
        password: { required },
        email: { required, email },
      },
    };
  },
  created() {
    this.getAllUsers();
  },
  mounted() {
    // Theme setup custome to bellalisa
    localStorage.setItem("navbarStyle", "card");
    localStorage.setItem("isFluid", true);
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
  },
  methods: {
    usernameFocus(val) {
      this.username_focused = val;
    },
    passwordFocus(val) {
      this.password_focused = val;
    },
    setUser(email) {
      console.log(email);
      this.username_key = email;
      this.formData.email = email;
    },
    gotToRegister() {
      this.$router.push("/register");
    },
    gotToForgotPassword() {
      this.$router.push("/forgot-password");
    },
    getAllUsers() {
      this.$store.commit("SET_LOADING", true);
      return UserService.getLoginUsers().then(
        (response) => {
          console.log(response.data);
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            this.users = response.data.data;
          } else {
            console.log(response.data.error);
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          toast.error(
            error.response.data.message ||
              (error.response && error.response.data) ||
              error.mesage ||
              error.toString(),
            {
              autoClose: 3000,
            }
          );
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    handleLogin() {
      this.v$.formData.email.$touch();
      this.v$.formData.password.$touch();
      if (this.v$.formData.$errors.length > 0) {
        window.scrollTo(0, 0);
        return;
      }
      this.$store.commit("SET_LOADING", true);
      this.$store.dispatch("auth/login", this.formData).then(
        (response) => {
          if (response.status == 200) {
            toast.success("Welcome aboard!", {
              autoClose: 3000,
            });
            if (response.data.user.is_admin == 1) {
              setTimeout(() => {
                this.$router.push("/dashboard");
              }, 1500);
            } else {
              setTimeout(() => {
                this.$router.push("/dashboard");
              }, 1500);
            }
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          toast.error(
            error.response.data.message ||
              (error.response && error.response.data) ||
              error.mesage ||
              error.toString(),
            { autoClose: 3000 }
          );
          console.log(error);
        }
      );
    },
  },
};
</script>
