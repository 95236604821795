<template>
  <div>
    <div class="card mb-3">
      <div class="card-body">
        <div class="row justify-content-between align-items-center">
          <div class="col-md">
            <h5 class="mb-2 mb-md-0">Quotation #AD20294</h5>
          </div>
          <div class="col-auto">
            <button
              class="btn btn-falcon-success btn-sm mb-2 mb-sm-0"
              type="button"
              @click="generateReport"
            >
              <span class="fas fa-arrow-down me-1"> </span>Download (.pdf)
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-3" id="element-to-print">
      <div class="card-body">
        <div class="row align-items-center text-center mb-3">
          <div class="col-sm-6 text-sm-start">
            <img src="assets/img/EduSpace.png" alt="invoice" width="150" />
          </div>
          <div class="col text-sm-end mt-3 mt-sm-0">
            <h2 class="mb-3">QUOTATION</h2>
            <h5 class="text-danger">JAZA INSURANCE AGENCY</h5>
            <p class="fs--1 mb-0">
              Western Heights, 6th Floor, Westlands, <br />
              P.O Box 1035-00200 Nairobi, Tel. 0740221615/0709186333, Email:
              info@jia.co.ke
            </p>
          </div>
        </div>
        <hr />
        <div class="row align-items-center">
          <div class="col-auto">
            <h6 class="text-500">Quotation to</h6>
            <h5>Samson Anami</h5>
            <p class="fs--1">
              <a href="mailto:sam@bulla.co.ke">sam@bulla.co.ke</a>|<a
                href="tel:0727031141"
                >0727031141</a
              >
            </p>
          </div>
          <div class="col-auto ms-auto">
            <div class="table-responsive">
              <table class="table table-sm table-borderless fs--1">
                <tbody>
                  <tr>
                    <th class="text-sm-end">Quotation Date:</th>
                    <td>2018-09-25</td>
                  </tr>
                  <tr>
                    <th class="text-sm-end">Quotation No:</th>
                    <td>14</td>
                  </tr>
                  <tr class="alert alert-success fw-bold">
                    <th class="text-sm-end">Amount:</th>
                    <td>KES 19,688.40</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="table-responsive scrollbar fs--1">
          <table
            class="table table-striped table-bordered border-bottom table-sm"
          >
            <thead class="light">
              <tr class="text-black" style="background-color: #edf2f9">
                <th class="border">CLASS</th>
                <th class="border">DETAILS</th>
                <th class="border text-center">SUM INSURED</th>
                <th class="border text-end">RATES</th>
                <th class="border text-end">PREMIUMS(KSH)</th>
                <th class="border text-center" colspan="2">REMARKS</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  class="align-top text-start"
                  style="width: 15%"
                  rowspan="20"
                >
                  <h6 class="mb-0 text-wrap">Motor Private Comprehensive</h6>
                </td>
                <td class="align-top text-start" rowspan="1">
                  <h6 class="mb-0 text-nowrap">Mercedes Benz</h6>
                  <p class="mb-0">KDJ 213L</p>
                </td>
                <td class="align-top text-center" rowspan="1">900,000</td>
                <td class="align-top text-end" rowspan="1">6.30 %</td>
                <td class="align-top text-end" rowspan="1">56,700</td>
                <td class="align-top text-start">
                  Minimum Basic Premium is Kshs.
                </td>
                <td class="align-top text-end">37,500</td>
              </tr>
              <tr>
                <td class="text-start" rowspan="1">
                  <u class="text-danger"> Optional Benefits</u><br />
                  Excess Protector<br />
                  PVT<br />
                  Courtesy Car<br />
                </td>
                <td class="text-start" rowspan="1">
                  <br />
                  Yes<br />
                  No<br />
                  Yes<br />
                </td>
                <td class="text-start" rowspan="1">
                  <br />
                  0.25%<br />
                  0.25%<br />
                  -<br />
                </td>
                <td class="text-end" rowspan="1">
                  <br />
                  2,250<br />
                  2,250<br />
                  -<br />
                </td>
                <td class="text-start" colspan="2">
                  <h6 class="text-center"><u> Excess/Limits </u></h6>
                  Own damage/ Partial theft - 2.5% of S.I min.15,000<br />
                  New & Young kshs 5,000/= additional<br />
                  Third party property damage- 5,000,000/=<br />
                  Passenger Legal Liability - 3M per person, 20M per event<br />
                  Theft : 10% of value: Min Kshs 30,000/-With Anti Theft
                  Device<br />
                  Theft (without ATD – 20% of value minimum Kshs. 30,000<br />
                </td>
              </tr>
              <tr>
                <td class="text-start" rowspan="2"></td>
                <td class="text-start fw-bold">
                  Add T/Levy<br />
                  Add IPHCF/Levy<br />
                  Stamp Duty<br />
                  <br />
                  <br />
                  <u>TOTAL PREMIUM</u>
                </td>
                <td class="text-start">
                  0.20%<br />
                  0.25%<br />
                  -<br />
                </td>
                <td class="text-end">
                  122<br />
                  153<br />
                  40<br />
                  <br />
                  <br />
                  <span class="text-end fw-bolder"><u>KES 7,573.50</u></span>
                </td>
                <td class="text-start">
                  <h6 class="text-start"><u>Free Benefits </u></h6>
                  Repair Authority<br />
                  Medical Expenses <br />
                  Towing charges <br />
                  Radio Cassette <br />
                  Windscreen <br />
                  Free valuation<br />
                </td>
                <td class="text-end">
                  <br />
                  50,000<br />
                  50,000<br />
                  50,000<br />
                  50,000<br />
                  30,000<br />
                  30,000<br />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row justify-content-en">
          <div class="col-auto">
            Prepared by: <b>Edith Kabaki</b> <br />
            <span><u>Head Of Business Development</u></span>
            <br />
            <span class="text-muted">Recommended Provider:</span>
            <br />
            <span class="text-primary"
              >AIG KENYA INSURANCE COMPANY LIMITED</span
            >
          </div>
          <div class="col-auto ms-auto">
            <table class="table table-sm table-borderless fs--1 text-end">
              <tr>
                <th class="text-900 border" colspan="2">Payment Details</th>
              </tr>
              <tr>
                <th class="text-900 border">M-PESA PAYBILL NO:</th>
                <td class="fw-semi-bold border">503200</td>
              </tr>
              <tr class="border-top">
                <th class="text-900 border">Account Name::</th>
                <td class="fw-semi-bold border">KBJ500L</td>
              </tr>
              <tr class="border-top border-top-2 fw-bolder text-900">
                <th class="border">Amount:</th>
                <td class="border">KES 7,573.50</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="card-footer bg-light">
        <p class="fs--1 mb-0 bt-n4">
          <strong>Notes: </strong>We hope you will find our quotation
          competitive and look forward to receive further instructions from you.
        </p>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="authentication-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="authentication-modal-label"
    aria-hidden="true"
  >
    <div class="modal-dialog mt-6" role="document">
      <div class="modal-content border-0">
        <div
          class="modal-header px-5 position-relative modal-shape-header bg-shape"
        >
          <div class="position-relative z-index-1 light">
            <h4 class="mb-0 text-white" id="authentication-modal-label">
              Register
            </h4>
            <p class="fs--1 mb-0 text-white">
              Please create your free Falcon account
            </p>
          </div>
          <button
            class="btn-close btn-close-white position-absolute top-0 end-0 mt-2 me-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body py-4 px-5">
          <form>
            <div class="mb-3">
              <label class="form-label" for="modal-auth-name">Name</label
              ><input
                class="form-control"
                type="text"
                autocomplete="on"
                id="modal-auth-name"
              />
            </div>
            <div class="mb-3">
              <label class="form-label" for="modal-auth-email"
                >Email address</label
              ><input
                class="form-control"
                type="email"
                autocomplete="on"
                id="modal-auth-email"
              />
            </div>
            <div class="row gx-2">
              <div class="mb-3 col-sm-6">
                <label class="form-label" for="modal-auth-password"
                  >Password</label
                ><input
                  class="form-control"
                  type="password"
                  autocomplete="on"
                  id="modal-auth-password"
                />
              </div>
              <div class="mb-3 col-sm-6">
                <label class="form-label" for="modal-auth-confirm-password"
                  >Confirm Password</label
                ><input
                  class="form-control"
                  type="password"
                  autocomplete="on"
                  id="modal-auth-confirm-password"
                />
              </div>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="modal-auth-register-checkbox"
              /><label class="form-label" for="modal-auth-register-checkbox"
                >I accept the <a href="index.html#!">terms </a>and
                <a href="index.html#!">privacy policy</a></label
              >
            </div>
            <div class="mb-3">
              <button
                class="btn btn-primary d-block w-100 mt-3"
                type="submit"
                name="submit"
              >
                Register
              </button>
            </div>
          </form>
          <div class="position-relative mt-5">
            <hr />
            <div class="divider-content-center">or register with</div>
          </div>
          <div class="row g-2 mt-2">
            <div class="col-sm-6">
              <a
                class="btn btn-outline-google-plus btn-sm d-block w-100"
                href="index.html#"
                ><span
                  class="fab fa-google-plus-g me-2"
                  data-fa-transform="grow-8"
                ></span>
                google</a
              >
            </div>
            <div class="col-sm-6">
              <a
                class="btn btn-outline-facebook btn-sm d-block w-100"
                href="index.html#"
                ><span
                  class="fab fa-facebook-square me-2"
                  data-fa-transform="grow-8"
                ></span>
                facebook</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-background-color: #edf2f9;
  --easy-table-header-item-padding: 10px 15px;
}
</style>

<script>
import mixins from "../mixins/index";
import CountUp from "vue-countup-v3";
import AppBar from "@/components/AppBar.vue";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import ReportService from "../services/report.service";
import ProviderService from "../services/provider.service";
import EasyDataTable from "vue3-easy-data-table";
import SimpleTypeahead from "vue3-simple-typeahead";
import "vue3-simple-typeahead/dist/vue3-simple-typeahead.css";

export default {
  mixins: [mixins],
  components: {
    AppBar,
    CountUp,
    NavDrawer,
    FooterComp,
    EasyDataTable,
    SimpleTypeahead,
  },
  data() {
    return {
      refreshed: 0,
      providers: [],
      daily_analysis: [],
      weekly_analysis: [],
      headers: [
        {
          text: "Provider",
          value: "name",
          sortable: true,
        },
        {
          text: "Type",
          value: "type",
          sortable: true,
        },
        {
          text: "Total (Ksh)",
          value: "amount",
          sortable: true,
          width: 120,
        },
      ],
      formData: {
        car_value: "",
        insurance_type: "MPC",
      },
      year: new Date().getFullYear(),
      last_15_years: this.generateLastYears(15),
      car_makes: [
        "Abarth",
        "Acura",
        "Alfa",
        "Aston",
        "Audi",
        "Bentley",
        "BMW",
        "Buick",
        "Cadillac",
        "Chevrolet",
        "Chrysler",
        "Citroen",
        "Dacia",
        "Ferrari",
        "Fiat",
        "Ford",
        "GMC",
        "Honda",
        "Hummer",
        "Hyundai",
        "Infiniti",
        "Isuzu",
        "Jaguar",
        "Jeep",
        "Kia",
        "Lamborghini",
        "Lancia",
        "Land",
        "Lexus",
        "Lincoln",
        "Lotus",
        "Maserati",
        "Mazda",
        "Mercedes",
        "Mercury",
        "Mini",
        "Mitsubishi",
        "Nissan",
        "Opel",
        "Peugeot",
        "Pontiac",
        "Porsche",
        "Ram",
        "Renault",
        "Saab",
        "Saturn",
        "Scion",
        "Seat",
        "Skoda",
        "Smart",
        "SsangYong",
        "Subaru",
        "Suzuki",
        "Tesla",
        "Toyota",
        "Volkswagen",
        "Volvo",
        "Wiesmann",
      ],
    };
  },
  created() {
    // this.forceReload();
    this.getQuotations();
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
  },
  watch: {
    formData: {
      handler(newVal, oldVal) {
        console.log(newVal);
        this.getQuotations();
      },
      deep: true,
    },
  },
  methods: {
    generateReport() {
      var element = document.getElementById("element-to-print");
      html2pdf(element, {
        margin: 0.1,
        filename: "JIA Quotation" + ".pdf",
        pagebreak: { after: ".sautDePage" },
        image: {
          type: "jpeg",
          quality: 2,
        },
        html2canvas: {
          scale: 2,
          letterRendering: true,
        },
        jsPDF: {
          unit: "in",
          format: "a4",
          orientation: "portrait",
        },
      });
    },
    selectItemEventHandler(name) {
      this.formData.car_make = name;
    },
    yearSelected(e) {
      this.formData.yom = e.target.value;
      console.log(this.formData);
    },
    goToCustomers() {},
    getTimegreetings() {
      const time = new Date().getHours();
      let greetings;
      if (time < 12) greetings = "Good Morning";
      else if (time >= 12 && time <= 17) greetings = "Good Afternoon";
      else greetings = "Good Evening";
      return greetings;
    },
    formatToCurrency(amount) {
      if (amount || amount == 0) {
        const internationalNumberFormat = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "KES",
        });
        return internationalNumberFormat.format(amount);
      } else {
        return amount;
      }
    },
    getQuotations() {
      this.$store.commit("SET_LOADING", true);
      return ProviderService.quote(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.providers = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getDailyAnalysis() {
      this.$store.commit("SET_LOADING", true);
      return ReportService.dailyAnalysis().then(
        (response) => {
          if (response.data.code == 200) {
            this.daily_analysis = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getWeeklyAnalysis() {
      this.$store.commit("SET_LOADING", true);
      return ReportService.weeklyAnalysis().then(
        (response) => {
          if (response.data.code == 200) {
            this.weekly_analysis = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
