<template>
  <!-- Start app bar  -->
  <nav
    class="navbar navbar-light navbar-glass navbar-top navbar-expand"
    style="display: none"
  >
    <button
      class="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarVerticalCollapse"
      aria-controls="navbarVerticalCollapse"
      aria-expanded="false"
      aria-label="Toggle Navigation"
    >
      <span class="navbar-toggle-icon"><span class="toggle-line"></span></span>
    </button>
    <a class="navbar-brand me-1 me-sm-3" href="index.html">
      <div class="d-flex align-items-center">
        <img
          class="me-2"
          src="assets/img/icons/spot-illustrations/EduSpace.png"
          alt=""
          height="32"
        />
        <!-- <span class="font-sans-serif">BI</span> -->
      </div>
    </a>
    <ul class="navbar-nav align-items-center d-none d-lg-block">
      <li class="nav-item">
        <!-- Start search -->
        <div class="search-box" data-list='{"valueNames":["title"]}'>
          <form
            class="position-relative"
            data-bs-toggle="search"
            data-bs-display="static"
          >
            <input
              class="form-control search-input fuzzy-search"
              type="search"
              placeholder="Search..."
              aria-label="Search"
            />
            <span class="fas fa-search search-box-icon"></span>
          </form>
          <div
            class="btn-close-falcon-container position-absolute end-0 top-50 translate-middle shadow-none"
            data-bs-dismiss="search"
          >
            <button
              class="btn btn-link btn-close-falcon p-0"
              aria-label="Close"
            ></button>
          </div>
          <div
            class="dropdown-menu border font-base start-0 mt-2 py-0 overflow-hidden w-100"
          >
            <div class="scrollbar list py-3" style="max-height: 24rem">
              <h6
                class="dropdown-header fw-medium text-uppercase px-card fs--2 pt-0 pb-2 border-bottom"
              >
                Modules
              </h6>
              <a
                v-for="item in sorted_modules"
                :key="item.title"
                class="dropdown-item fs--1 px-card py-1 hover-primary"
                @click="goToLink(item.route)"
                role="button"
              >
                <div class="d-flex align-items-center">
                  <div class="fw-normal title">
                    <span class="fas fa-circle me-2 text-300 fs--2"></span
                    >{{ item.title }} | <br />
                    <a
                      v-for="(subItem, i) in item.child"
                      @click="goToLink(subItem.route)"
                      :key="i"
                      class=""
                      ><span class="fas fa-check me-2 text-300 fs--2"></span
                      >{{ subItem.title }}<br
                    /></a>
                  </div>
                </div>
              </a>
              <hr class="text-200 dark__text-900" />
            </div>
            <div class="text-center mt-n3">
              <p class="fallback fw-bold fs-1 d-none">No Result Found.</p>
            </div>
          </div>
        </div>
        <!-- End search  -->
      </li>
    </ul>
    <ul class="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center">
      <li class="nav-item">
        <div class="theme-control-toggle fa-icon-wait px-2">
          <input
            class="form-check-input ms-0 theme-control-toggle-input"
            id="themeControlToggle"
            type="checkbox"
            data-theme-control="theme"
            value="dark"
          /><label
            class="mb-0 theme-control-toggle-label theme-control-toggle-light"
            for="themeControlToggle"
            data-bs-toggle="tooltip"
            data-bs-placement="left"
            title="Switch to light theme"
            ><span class="fas fa-sun fs-0"></span></label
          ><label
            class="mb-0 theme-control-toggle-label theme-control-toggle-dark"
            for="themeControlToggle"
            data-bs-toggle="tooltip"
            data-bs-placement="left"
            title="Switch to dark theme"
            ><span class="fas fa-moon fs-0"></span
          ></label>
        </div>
      </li>
      <!-- User Drop down  -->
      <li class="nav-item dropdown">
        <a
          class="nav-link pe-0 ps-2"
          id="navbarDropdownUser"
          role="button"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div class="avatar avatar-xl">
            <img
              class="rounded-circle"
              src="assets/img/team/avatar.png"
              alt=""
            />
          </div>
        </a>
        <div
          class="dropdown-menu dropdown-caret dropdown-caret dropdown-menu-end py-0"
          aria-labelledby="navbarDropdownUser"
        >
          <div class="bg-white dark__bg-1000 rounded-2 py-2">
            <a class="dropdown-item text-warning" href="https://bulla.co.ke"
              ><span class="fas fa-crown me-1"></span
              ><span>{{ user.first_name }} - {{ user.last_name }}</span></a
            >
            <a class="dropdown-item">
              Role: <span class="text-primary"> {{ user.role_name }}</span>
            </a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" @click="goToProfile"
              >Profile &amp; account</a
            >
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" @click="goToSettings">Business Settings</a>
            <a class="dropdown-item" @click="handleLogout">Logout</a>
          </div>
        </div>
      </li>
      <!--End  User Drop down  -->
    </ul>
  </nav>
  <!-- Start menus  -->
  <!-- In vertical & combo  -->
  <nav
    class="navbar navbar-light navbar-glass navbar-top navbar-expand-lg"
    style="display: none"
    data-move-target="#navbarVerticalNav"
    data-navbar-top="combo"
  >
    <button
      class="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarVerticalCollapse"
      aria-controls="navbarVerticalCollapse"
      aria-expanded="false"
      aria-label="Toggle Navigation"
    >
      <span class="navbar-toggle-icon"><span class="toggle-line"></span></span>
    </button>
    <a class="navbar-brand me-1 me-sm-3" href="bulla.co.ke">
      <div class="d-flex align-items-center">
        <img
          class="me-2"
          src="assets/img/icons/spot-illustrations/EduSpace.png"
          alt=""
          height="32"
        />
        <!-- <span class="font-sans-serif">BI</span> -->
      </div>
    </a>
    <div class="collapse navbar-collapse scrollbar" id="navbarStandard">
      <!-- Combo view  -->
      <ul class="navbar-nav" data-top-nav-dropdowns="data-top-nav-dropdowns">
        <li
          v-for="item in sorted_modules"
          :key="item.title"
          class="nav-item dropdown"
        >
          <!-- Without child  -->
          <a
            v-if="!item.child"
            class="nav-link"
            @click="goToLink(item.route)"
            role="button"
            :id="item.route"
            >{{ item.title }}
          </a>
          <!-- With child  -->
          <a
            v-if="item.child"
            class="nav-link dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            :id="item.title"
          >
            {{ item.title }}</a
          >
          <div
            class="dropdown-menu dropdown-caret dropdown-menu-card border-0 mt-0"
            aria-labelledby="dashboards"
          >
            <div class="bg-white dark__bg-1000 rounded-3 py-2">
              <a
                v-for="(subItem, i) in item.child"
                @click="goToLink(subItem.route)"
                :key="i"
                class="dropdown-item link-600 fw-medium"
                >{{ subItem.title }}</a
              >
            </div>
          </div>
        </li>
      </ul>
    </div>
    <ul class="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center">
      <li class="nav-item dropdown">
        <a
          class="nav-link pe-0 ps-2"
          id="navbarDropdownUser"
          role="button"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div class="avatar avatar-xl">
            <img
              class="rounded-circle"
              src="assets/img/team/avatar.png"
              alt=""
            />
          </div>
        </a>
        <div
          class="dropdown-menu dropdown-caret dropdown-caret dropdown-menu-end py-0"
          aria-labelledby="navbarDropdownUser"
        >
          <div class="bg-white dark__bg-1000 rounded-2 py-2">
            <a class="dropdown-item fw-bold text-warning" href="widgets.html#!"
              ><span class="fas fa-crown me-1"></span><span>Go Pro</span></a
            >
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="pages/user/profile.html"
              >Profile &amp; account</a
            >
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="pages/user/settings.html"
              >Settings</a
            >
            <a class="dropdown-item" @click="handleLogout">Logout</a>
          </div>
        </div>
      </li>
    </ul>
  </nav>
  <!-- End of combo menu  -->
  <!-- <div class="d-flex mb-4 mt-3"><span class="fa-stack me-2 ms-n1">
      <i class="fas fa-circle fa-stack-2x text-300"></i><i
        class="fa-inverse fa-stack-1x text-primary fas fa-percentage"></i></span>
    <div class="col">
      <h5 class="mb-0 text-primary position-relative"><span class="bg-200 dark__bg-1100 pe-3">Number Stats &amp;
          Charts</span><span class="border position-absolute top-50 translate-middle-y w-100 start-0 z-index--1"></span>
      </h5>
      <p class="mb-0">You can easily show your stats content by using these cards.</p>
    </div>
  </div> -->
  <!-- End app bar -->
</template>

<script>
export default {
  name: "AppBar",
  beforeCreate() {
    if (!localStorage.getItem("user")) {
      this.$router.push("/login");
    }
  },
  computed: {
    isAdmin() {
      return this.$store.state.auth.user.is_admin;
    },
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
    sortedModules() {
      let modules = JSON.parse(localStorage.getItem("user")).modules;
      return this.sortModule(modules);
    },
  },
  mounted() {},
  data() {
    return {
      sorted_modules: [],
    };
  },
  async created() {
    this.sorted_modules = await this.sortModule();
  },
  methods: {
    async sortModule() {
      let modules = await JSON.parse(localStorage.getItem("user")).modules;
      let sorted = modules.sort((a, b) => a.order - b.order);
      return sorted;
    },
    gotToPOS() {
      this.$router.push("/pos");
    },
    goToProfile() {
      this.$router.push("/profile");
    },
    goToSettings() {
      this.$router.push("/settings");
    },
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    goToLink(link_name) {
      console.log(link_name);
      this.$router.push(link_name);
    },
  },
};
</script>
