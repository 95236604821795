<template>
  <div>
    <div
      class="card mb-3"
      id="ordersTable"
      data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'
    >
      <div class="card-header">
        <div class="row flex-between-center border-bottom pb-1">
          <div class="col-4 col-sm-auto align-items-center pe-0">
            <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              <i class="fa fa-users"></i>
              Roles
            </h5>
            <p class="fs--1 fw-1 text-500 mb-0 text-nowrap">
              Manage user roles
            </p>
          </div>
          <div class="col-8 col-sm-auto ms-auto text-end ps-0">
            <div id="orders-actions" class="d-flex">
              <div class="search-box fs--1">
                <input
                  class="form-control search-input"
                  type="text"
                  placeholder="Search..."
                  aria-label="Search"
                  v-model="searchValue"
                />
                <span class="fas fa-search search-box-icon"></span>
              </div>
              <button
                class="btn btn-sm btn-falcon-primary btn-sm ms-2"
                type="button"
                @click="newItem"
                data-bs-toggle="modal"
                data-bs-target="#add-modal"
              >
                <span class="fas fa-plus" data-fa-transform=""></span>
              </button>
              <button
                @click="forceReload"
                class="btn btn-sm btn-falcon-primary ms-2"
              >
              <i class="fa fa-retweet"></i>
                <i class="fa-solid fa-arrows-rotate"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body px-3 py-0">
        <!-- Menu items table  -->
        <EasyDataTable
          id="tour-step-1"
          table-class-name="customize-table"
          alternating
          buttons-pagination
          :headers="headers"
          :items="resources"
          :search-field="searchField"
          :search-value="searchValue"
          :loading="loading"
        >
          <template #loading>
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </template>
          <template #item-permissions="item">
            <div class="my-2">
              <span
                v-for="(i, perm) in item.permissions"
                :key="perm"
                class="badge badge-soft-success m-1 p-2 scrollable"
              >
                {{ perm }}</span
              >
            </div>
          </template>
          <template #item-action="item">
            <div class="dropdown font-sans-serif position-static">
              <button
                class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="order-dropdown-0"
                data-bs-toggle="dropdown"
                data-boundary="viewport"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="fas fa-ellipsis-h fs--1"></span>
              </button>
              <div
                class="dropdown-menu dropdown-menu-end border py-0"
                aria-labelledby="order-dropdown-0"
              >
                <div class="py-2">
                  <a
                    class="dropdown-item"
                    role="button"
                    data-bs-toggle="modal"
                    data-bs-target="#edit-modal"
                    @click="editItem(item)"
                    >Edit</a
                  >
                  <div class="dropdown-divider"></div>
                  <a
                    class="dropdown-item text-danger"
                    role="button"
                    @click="deleteRecource(item)"
                    >Delete</a
                  >
                </div>
              </div>
            </div>
          </template>
        </EasyDataTable>
        <!-- End Menu items table  -->
      </div>
      <div class="card-footer">
        <!-- footer content  -->
      </div>
    </div>
    <!-- Start edit modal  -->
    <div
      class="modal fade"
      id="edit-modal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">Edit Role</h4>
            </div>
            <div class="px-4">
              <div class="d-flex">
                <div class="flex-1">
                  <div class="row">
                    <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <label class="col-form-label" for="recipient-name"
                        >Name:</label
                      >
                      <input
                        class="form-control"
                        id="recipient-name"
                        type="text"
                        placeholder="e.g Administrator"
                        v-model="formData.title"
                        :class="
                          v$.formData.title.$dirty && v$.formData.title.$invalid
                            ? `is-invalid`
                            : v$.formData.title.$dirty
                            ? `is-valid`
                            : ``
                        "
                      />
                      <div
                        v-for="error of v$.formData.title.$errors"
                        :key="error.$uid"
                        class="invalid-feedback"
                        style="color: red"
                      >
                        This field is invalid
                      </div>
                    </div>
                    <!-- <div class="mb3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <label class="col-form-label" for="message-text"
                            >Slug:</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            placeholder="e.g administrator"
                            v-model="formData.slug"
                            :class="
                              v$.formData.slug.$dirty &&
                              v$.formData.slug.$invalid
                                ? `is-invalid`
                                : v$.formData.slug.$dirty
                                ? `is-valid`
                                : ``
                            "
                          />
                          <div
                            v-for="error of v$.formData.slug.$errors"
                            :key="error.$uid"
                            class="invalid-feedback"
                            style="color: red"
                          >
                            This field is invalid
                          </div>
                        </div> -->
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <div class="flex-1">
                  <h5 class="mb-2 fs-0 border-bottom">Select Rights</h5>
                  <p class="text-word-break fs--1">
                    <button
                      v-for="(perm, i) in computed_permissions"
                      :key="i"
                      @click="selectRight(perm.title)"
                      class="btn m-1 p-1"
                      :class="
                        Array.isArray(selected_rights)
                          ? selected_rights.includes(perm.title)
                            ? 'btn-success'
                            : 'btn-falcon-info'
                          : ''
                      "
                    >
                      {{ perm.title }}
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-success ms-auto"
              role="button"
              @click="updateResource"
            >
              <span class="fa fa-check me-2"></span>Update
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End edit modal  -->
    <!-- Start add modal  -->
    <div
      class="modal fade"
      id="add-modal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">Add Role</h4>
            </div>
            <div class="px-4">
              <div class="d-flex">
                <div class="flex-1">
                  <div class="row">
                    <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <label class="col-form-label" for="recipient-name"
                        >Name:</label
                      >
                      <input
                        class="form-control"
                        id="recipient-name"
                        type="text"
                        placeholder="e.g Administrator"
                        v-model="formData.title"
                        :class="
                          v$.formData.title.$dirty && v$.formData.title.$invalid
                            ? `is-invalid`
                            : v$.formData.title.$dirty
                            ? `is-valid`
                            : ``
                        "
                      />
                      <div
                        v-for="error of v$.formData.title.$errors"
                        :key="error.$uid"
                        class="invalid-feedback"
                        style="color: red"
                      >
                        This field is invalid
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <div class="flex-1">
                  <h5 class="mb-2 fs-0 border-bottom pb-2">Select Rights</h5>
                  <!-- <div
                        class="mb3 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2"
                      >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          placeholder="search..."
                          v-model="search_key"
                  @change="searching"
                        />
                      </div> -->
                  <p class="text-word-break fs--1">
                    <button
                      v-for="(perm, i) in permissions"
                      :key="i"
                      @click="selectRight(perm.title)"
                      class="btn m-1 p-1"
                      :class="
                        Array.isArray(selected_rights)
                          ? selected_rights.includes(perm.title)
                            ? 'btn-success'
                            : 'btn-falcon-info'
                          : ''
                      "
                    >
                      {{ perm.title }}
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-success ms-auto"
              role="button"
              @click="saveResource"
            >
              <span class="fa fa-check me-2"></span>Update
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End add modal  -->
  </div>
</template>

<script>
import $ from "jquery";
import AppBar from "@/components/AppBar.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import mixins from "../mixins/index";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import RoleService from "../services/role.service";
import RightsService from "../services/right.service";

import LoaderOverlay from "@/components/LoaderOverlay.vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import EasyDataTable from "vue3-easy-data-table";

const now = new Date();

export default {
  mixins: [mixins],
  name: "TicketAnalysisReportView",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      formData: {
        title: { required },
      },
    };
  },
  components: {
    NavDrawer,
    AppBar,
    flatPickr,
    FooterComp,
    LoaderOverlay,
    EasyDataTable,
  },
  data() {
    return {
      search_key: "",
      selected_rights: [],
      searchValue: "",
      table_keys: 1,
      formData: {
        title: "",
        permissions: [],
      },
      resources: [],
      permissions: [],
      orders_table_key: 0,
      computed_permissions: [],
      searchField: ["title", "icon", "detail", "route", "link"],
      headers: [
        { text: "#", value: "id", sortable: true, width: 50 },
        { text: "NAME", value: "title", sortable: true },
        { text: "Action", value: "action", width: 100 },
      ],
    };
  },
  created() {
    this.getData();
    this.getPermissions();
  },
  watch: {
    resources(newVal, oldVal) {
      console.log("resource length", newVal.length);
      this.orders_table_key += 1;
    },
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    computed_rights() {
      return this.selected_rights;
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    searching(e) {
      let newVal = e.target.value;
      console.log("newVal", newVal);
      this.computed_permissions = this.permissions.filter(
        (el) => item.indexOf(newVal) > -1
      );
    },
    newItem() {
      this.formData = {
        title: "",
        slug: "",
        permissions: [],
      };
      this.selected_rights = [];
    },
    editItem(item) {
      this.selected_rights = item.permissions;
      this.formData = item;
    },
    selectRight(name) {
      if (Array.isArray(this.selected_rights)) {
        if (this.selected_rights.includes(name)) {
          this.selected_rights.splice(this.selected_rights.indexOf(name), 1);
        } else {
          this.selected_rights.push(name);
        }
      } else {
        this.selected_rights = [];
        this.selected_rights.push(name);
      }
    },
    toggleShowOnMain() {
      this.formData.show_on_main_page = !this.formData.show_on_main_page;
    },
    toggleActive() {
      this.formData.active = !this.formData.active;
    },
    saveResource() {
      this.v$.formData.title.$touch();
      if (this.v$.formData.$invalid) {
        return;
      }
      this.formData.permissions = this.selected_rights;
      this.formData.slug = this.formData.title.toLowerCase();
      this.$store.commit("SET_LOADING", true);
      RoleService.store(this.formData).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            // Clear modals
            $("#add-modal").hide();
            $("body").removeClass("modal-open");
            $(".modal-backdrop").remove();
            this.table_keys += 1;
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getData();
          } else {
            toast.error(
              response.data.error || response.errors[0] || response.message
            );
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          toast.error(error.response.data.message);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    updateResource() {
      this.$store.commit("SET_LOADING", true);
      this.formData.permissions = this.selected_rights;
      this.formData.slug = this.formData.title.toLowerCase();
      RoleService.update(this.formData).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getData();
            // Clear modals
            $("#edit-modal").removeClass("fade").hide();
            $(".modal-backdrop").remove();
          } else {
            toast.error(
              response.data.error ||
                response.data.errors.at(0) ||
                response.data.message
            );
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          toast.error(error.response.data.message);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    deleteRecource(resource) {
      let sure = confirm(
        "Are you sure you want to delete? you cannot undo this action."
      );
      if (sure) {
        this.$store.commit("SET_LOADING", true);
        RoleService.delete(resource).then(
          (response) => {
            if (response.data.code == 200) {
              toast.success(response.data.message, {
                autoClose: 2000,
              });
              this.getData();
              $("#edit-modal").removeClass("fade").hide();
              $(".modal-backdrop").remove();
            } else {
              toast.error(response.data.error, {
                autoClose: 2000,
              });
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    },
    getPermissions() {
      this.$store.commit("SET_LOADING", true);
      return RightsService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.permissions = response.data.data;
            this.computed_permissions = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getData() {
      this.$store.commit("SET_LOADING", true);
      return RoleService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
