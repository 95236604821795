import { createRouter, createWebHashHistory } from "vue-router";
import Login from "../views/LoginView.vue";
import BlankView from "../views/BlankView.vue";
import UsersView from "../views/UsersView.vue";
import RolesView from "../views/RolesView.vue";
import ModulesView from "../views/ModulesView.vue";
import PromoteView from "../views/PromoteView.vue";
import ClassesView from "../views/ClassesView.vue";
import SubjectsView from "../views/SubjectsView.vue";
import StudentsView from "../views/StudentsView.vue";
import DashboardView from "../views/DashboardView.vue";
import AdmissionView from "../views/AdmissionView.vue";
import StudentsIdView from "../views/StudentsIdView.vue";
import OpenOrdersView from "../views/OpenOrdersView.vue";
import SalesReportView from "../views/SalesReportView.vue";
import FeeStructureView from "../views/FeeStructureView.vue";
import ResetPasswordView from "../views/ResetPasswordView.vue";
import NewApplicationView from "../views/NewApplicationView.vue";
import ForgotPasswordView from "../views/ForgotPasswordView.vue";
import PaymentAnalysisView from "../views/PaymentAnalysisView.vue";
import StudentsByClassView from "../views/StudentsByClassView.vue";
import QuotationSampleView from "../views/QuotationSampleView.vue";
import AdmissionLettersView from "../views/AdmissionLettersView.vue";
import PremiumProvidersView from "../views/PremiumProvidersView.vue";
import QuotationTemplateView from "../views/QuotationTemplateView.vue";
import TicketAnalysisReportView from "../views/TicketAnalysisReportView.vue";
import ClassesAssignSubjectView from "../views/ClassesAssignSubjectView.vue";

const routes = [
  {
    path: "/user.index",
    name: "user.index",
    component: UsersView,
    meta: { layout: "main" },
  },
  {
    path: "/role.index",
    name: "role",
    component: RolesView,
    meta: { layout: "main" },
  },
  {
    path: "/new-application",
    name: "new-application",
    component: NewApplicationView,
    meta: { layout: "main" },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardView,
    meta: { layout: "main" },
  },
  {
    path: "/new-admission/:id?",
    name: "new-admission",
    component: AdmissionView,
    meta: { layout: "main" },
  },
  {
    path: "/admission-letter/:id?",
    name: "admission-letter",
    component: AdmissionLettersView,
    meta: { layout: "main" },
  },
  {
    path: "/module.index",
    name: "module.index",
    component: ModulesView,
    meta: { layout: "main" },
  },
  {
    path: "/subjects",
    name: "subjects",
    component: SubjectsView,
    meta: { layout: "main" },
  },
  {
    path: "/classes",
    name: "classes",
    component: ClassesView,
    meta: { layout: "main" },
  },
  {
    path: "/premium-providers",
    name: "premium-providers",
    component: PremiumProvidersView,
    meta: { layout: "main" },
  },
  {
    path: "/quotation/:data?",
    name: "quotation",
    component: QuotationTemplateView,
  },
  {
    path: "/students",
    name: "students",
    component: StudentsView,
    meta: { layout: "main" },
  },
  {
    path: "/students-byclass/:id",
    name: "students-byclass",
    component: StudentsByClassView,
    meta: { layout: "main" },
  },
  {
    path: "/assign-subjects",
    name: "assign-subjects",
    component: ClassesAssignSubjectView,
    meta: { layout: "main" },
  },
  {
    path: "/scores",
    name: "scores",
    component: ClassesAssignSubjectView,
    meta: { layout: "main" },
  },
  
  {
    path: "/fee-structure",
    name: "fee-structure",
    component: FeeStructureView,
    meta: { layout: "main" },
  },
  {
    path: "/student-ids",
    name: "student-ids",
    component: StudentsIdView,
    meta: { layout: "main" },
  },
  {
    path: "/promote-students",
    name: "promote-students",
    component: PromoteView,
    meta: { layout: "main" },
  },

  {
    path: "/quotation-sample",
    name: "quotation-sample",
    component: QuotationSampleView,
    meta: { layout: "main" },
  },
  {
    path: "/reset-password/:token",
    name: "reset-password",
    component: ResetPasswordView,
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPasswordView,
  },
  {
    path: "/premium-providers",
    name: "premium-providers",
    component: PremiumProvidersView,
  },
  {
    path: "/blank",
    name: "blank",
    component: BlankView,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/",
    name: "init",
    component: Login,
  },
  {
    path: "/pos-open-order",
    name: "pos-open-order",
    component: OpenOrdersView,
  },

  {
    path: "/sales-analysis-report",
    name: "sales-analysis-report",
    component: SalesReportView,
  },
  {
    path: "/ticket-analysis",
    name: "ticket_analysis",
    component: TicketAnalysisReportView,
  },
  {
    path: "/payment-analysis-report",
    name: "payment_analysis_report",
    component: PaymentAnalysisView,
  },
];

const router = createRouter({
  mode: "history",
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  base: process.env.VUE_APP_ROOT_URL,
  history: createWebHashHistory(process.env.VUE_APP_ROOT_URL),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  },
});

// control auth in all routes
router.beforeEach((to, from, next) => {
  const publicPages = [
    "/login",
    "/register/enterprise",
    "/register/individual",
    "/register/starter",
    "/register/standard",
    "/home",
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("token");
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});

export default router;
