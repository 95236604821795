<template>
  <div class="container" data-layout="container">
    <div class="content">
      <div class="card mb-3 mt-4">
        <div class="card-body">
          <!-- Desktop  -->
          <div
            class="row justify-content-between align-items-center"
          >
            <div class="col-auto">
              <!-- <button class="btn btn-falcon-success btn-sm mb-2 mb-sm-0" type="button" @click="generateReport">
                <span class="fas fa-download me-1"> </span>Download (.pdf)
              </button> -->
              <a
                :href="download_url"
                target="_blank"
                class="btn btn-falcon-info btn-sm mb-2 mb-sm-0"
                type="button"
              >
                <span class="fas fa-cloud me-1"> </span>Download (.pdf)
              </a>

              <!-- <button class="btn btn-falcon-primary btn-sm mb-2 mb-sm-0 ms-2" type="button" @click="uploadQuotation">
                <span class="fas fa-cloud me-1"> </span>Save Quotation
              </button> -->
            </div>
          </div>
          <!-- Mobile  -->
          <!-- <div class="d-flex d-md-none row justify-content-center align-items-center txt-center">
            <div class="col-auto text-center">
              <a :href="download_url" class="btn btn-falcon-info btn-sm mb-2 mb-sm-0" type="button">
                <span class="fas fa-cloud me-1"> </span>Download Mob (.pdf)
              </a>
              <button class="btn btn-falcon-primary btn-sm mb-2 mb-sm-0 ms-2" type="button" @click="uploadQuotation">
                <span class="fas fa-cloud me-1"> </span>Save Quotation
              </button>
            </div>
          </div> -->
        </div>
      </div>
      <!-- Start deskop -->
      <div class="card mt-n2 d-none d-md-block" id="element-to-print">
        <div class="d-flex justify-content-center">
          <h2 class="mx-auto mt-4 position-absolute fw-bold">QUOTATION</h2>
        </div>
        <div class="card-body mt-n3">
          <div class="row align-items-center text-center mb-3">
            <div class="col-sm-6 text-sm-start">
              <img src="assets/img/quotation_logo.png" alt="invoice" width="150" />
            </div>
            <div class="col text-sm-end mt-3 mt-sm-0">
              <h5 class="text-danger">JAZA INSURANCE AGENCY</h5>
              <p class="fs--1 mb-0">
                Western Heights, 6th Floor, Westlands, <br />
                P.O Box 1035-00200 Nairobi,<br />
                Tel. 0740221615/0709186333,<br />
                Email:info@jia.co.ke
              </p>
            </div>
          </div>
          <div
            v-if="quotationData.form_data"
            class="row align-items-center border-top"
          >
            <div class="col-auto">
              <h6 class="text-500">Quotation to</h6>
              <h5>{{ quotationData.form_data.customer_name }}</h5>
              <p class="fs--1">
                <a :href="`mailto:${quotationData.form_data.email}`">{{
                  quotationData.form_data.email
                }}</a
                >|<a :href="`tel:${quotationData.form_data.phone}`">{{
                  quotationData.form_data.phone
                }}</a>
              </p>
            </div>
            <div class="col-auto ms-auto">
              <div class="table-responsive">
                <table class="table table-sm table-borderless fs--1">
                  <tbody>
                    <tr class="text-end">
                      <th>Quotation Date:</th>
                      <td>{{ formatDateHuman(today) }}</td>
                    </tr>
                    <tr class="text-end">
                      <th>Quotation No:</th>
                      <td>
                        {{
                          `JIA/${generateRandom(100000, 999999)} ${
                            today.getMonth() + 1
                          } /${today.getFullYear()}`
                        }}
                      </td>
                    </tr>
                    <tr class="alert alert-success fw-bold text-end">
                      <th class="text-sm-end">Amount:</th>
                      <td>
                        KES
                        {{
                          quotationData.amount
                            ? formatToCurrency(Math.ceil(quotationData.amount))
                            : 0
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="table-responsive scrollbar fs--1">
            <table
              class="table table-striped table-bordered border-bottom table-sm"
            >
              <thead class="light">
                <tr class="text-black" style="background-color: #edf2f9">
                  <th class="border">CLASS</th>
                  <th class="border">DETAILS</th>
                  <th class="border text-center">SUM INSURED</th>
                  <th class="border text-end">RATES</th>
                  <th class="border text-end">PREMIUMS(KSH)</th>
                  <th class="border text-center" colspan="2">REMARKS</th>
                </tr>
              </thead>
              <tbody v-if="quotationData.form_data && quotationData.fields">
                <tr>
                  <td
                    class="align-top text-start"
                    style="width: 15%"
                    rowspan="20"
                  >
                    <h6 class="mb-0 text-nowrap">
                      {{
                        quotationData.form_data.insurance_type == "MPC"
                          ? "Motor Private Comprehensive (MPC)"
                          : "Third Party Only (TPO)"
                      }}
                    </h6>
                  </td>
                  <td class="align-top text-start" rowspan="1">
                    <h6 class="mb-0 text-nowrap">
                      {{ quotationData.form_data.car_make }}
                    </h6>
                    <p class="mb-0">
                      {{ quotationData.reg_no ? quotationData.reg_no : "-" }}
                    </p>
                  </td>
                  <td class="align-top text-center" rowspan="1">
                    {{ formatToCurrency(quotationData.car_value) }}
                  </td>
                  <td class="align-top text-end" rowspan="1">
                    {{
                      quotationData.fields.rate
                        ? `${formatToCurrency(quotationData.fields.rate)}%`
                        : "N/A"
                    }}
                  </td>
                  <td class="align-top text-end" rowspan="1">
                    {{ formatToCurrency(quotationData.fields.sub_total) }}
                  </td>
                  <td class="align-top text-start">Minimum Basic Premium.</td>
                  <td class="align-top text-end">
                    KES
                    {{ formatToCurrency(quotationData.fields.minimum_basic) }}
                  </td>
                </tr>
                <tr>
                  <td class="text-start" rowspan="1">
                    <u class="text-danger"> Optional Benefits</u><br />
                    Excess Protector<br />
                    PVT<br />
                    Courtesy Car<br />
                  </td>
                  <td class="text-center" rowspan="1">
                    <br />
                    <mark>{{ getExcessProtector() }}</mark
                    ><br />
                    <mark>{{
                      quotationData.fields.pvt ? quotationData.fields.pvt : "No"
                    }}</mark
                    ><br />
                    <mark>{{
                      quotationData.fields.courtesy_car
                        ? quotationData.fields.courtesy_car
                        : "No"
                    }}</mark
                    ><br />
                  </td>
                  <td class="text-end" rowspan="1">
                    <br />
                    {{
                      quotationData.fields.excess_protector_rate
                        ? formatToCurrency(
                            quotationData.fields.excess_protector_rate
                          ) + "%"
                        : "-"
                    }}<br />
                    {{
                      quotationData.fields.pvt_rate
                        ? formatToCurrency(quotationData.fields.pvt_rate) + "%"
                        : "-"
                    }}<br />
                    -<br />
                  </td>
                  <td class="text-end" rowspan="1">
                    <br />

                    {{
                      quotationData.fields.excess_protector_amount
                        ? formatToCurrency(
                            quotationData.fields.excess_protector_amount
                          )
                        : "-"
                    }}<br />
                    {{
                      quotationData.fields.pvt_amount
                        ? formatToCurrency(quotationData.fields.pvt_amount)
                        : "-"
                    }}<br />
                    {{
                      quotationData.fields.courtesy_car
                        ? formatToCurrency(quotationData.fields.courtesy_car)
                        : "-"
                    }}<br />
                  </td>
                  <td class="text-start" colspan="2">
                    <h6 class="text-center"><u> Excess/Limits </u></h6>
                    Own damage/ Partial theft - 2.5% of S.I min.15,000<br />
                    New & Young kshs 5,000/= additional<br />
                    Third party property damage- 5,000,000/=<br />
                    Passenger Legal Liability - 3M per person, 20M per event<br />
                    Theft : 10% of value: Min Kshs 30,000/-With Anti Theft
                    Device<br />
                    Theft (without ATD – 20% of value minimum Kshs. 30,000<br />
                  </td>
                </tr>
                <tr>
                  <td class="text-start" rowspan="2"></td>
                  <td class="text-start fw-bold">
                    T/Levy<br />
                    IPHCF/Levy<br />
                    Stamp Duty<br />
                    <br />
                    <br />
                    <u>TOTAL PREMIUM</u>
                  </td>
                  <td class="text-start">
                    0.20%<br />
                    0.25%<br />
                    -<br />
                  </td>
                  <td class="text-end">
                    {{ formatToCurrency(quotationData.fields.t_levy) }}<br />
                    {{ formatToCurrency(quotationData.fields.iphcf_levy)
                    }}<br />
                    40<br />
                    <br />
                    <br />
                    <span class="text-end fw-bolder"
                      ><u
                        >KES
                        {{
                          quotationData.amount
                            ? formatToCurrency(Math.ceil(quotationData.amount))
                            : 0
                        }}</u
                      ></span
                    >
                  </td>
                  <td class="text-start">
                    <h6 class="text-start"><u>Free Benefits </u></h6>
                    Repair Authority<br />
                    Medical Expenses <br />
                    Towing charges <br />
                    Radio Cassette <br />
                    Windscreen <br />
                    Free valuation<br />
                  </td>
                  <td class="text-end">
                    <br />
                    50,000<br />
                    50,000<br />
                    50,000<br />
                    50,000<br />
                    30,000<br />
                    30,000<br />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row justify-content-en">
            <div class="col-auto">
              <span class="text-mut fw-bold">Recommended Provider:</span>
              <span class="text-danger">{{ quotationData.name }}</span>
              <br />
              <br />
              Prepared by: <b>{{ user.first_name }} {{ user.last_name }}</b>
              <br />
              <span
                ><u>{{ user.role }}</u></span
              >
            </div>
            <div class="col-auto ms-auto">
              <table class="table table-sm table-borderless fs--1 text-end">
                <tr>
                  <th class="text-900 border" colspan="2">Payment Details</th>
                </tr>
                <tr>
                  <th class="text-900 border">M-PESA PAYBILL NO:</th>
                  <td class="fw-semi-bold border">
                    {{ quotationData.paybill_no }}
                  </td>
                </tr>
                <tr class="border-top">
                  <th class="text-900 border">Account Name::</th>
                  <td class="fw-semi-bold border">
                    {{
                      quotationData.reg_no ? quotationData.reg_no : "PLATE NO."
                    }}
                  </td>
                </tr>
                <tr class="border-top border-top-2 fw-bolder text-900">
                  <th class="border">Amount:</th>
                  <td class="border">
                    KES
                    {{
                      quotationData.amount
                        ? formatToCurrency(Math.ceil(quotationData.amount))
                        : 0
                    }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="card-footer bg-light mt-n3">
          <p class="fs--1 mb-0 bt-n4">
            <strong>Notes: </strong>We hope you will find our quotation
            competitive and look forward to receive further instructions from
            you.
          </p>
        </div>
      </div>
      <!-- End deskop -->

      <!-- Start mobile -->
      <div class="card mt-1 d-block d-md-none" id="element-to-print">
        <div class="card-body mt-n3">
          <div class="row align-items-center text-center mb-3">
            <div class="col-sm-6 text-sm-start">
              <img src="assets/img/quotation_logo.png" alt="invoice" width="80" />
            </div>
            <div class="col text-sm-end mt-3 mt-sm-0">
              <h5 class="text-danger">JAZA INSURANCE AGENCY</h5>
              <p class="fs--1 mb-0">
                Western Heights, 6th Floor, Westlands, <br />
                P.O Box 1035-00200 Nairobi,<br />
                Tel. 0740221615/0709186333,<br />
                Email:info@jia.co.ke
              </p>
            </div>
            <div class="d-flex justify-content-center">
              <h2 class="mx-auto my-2 fw-bold">QUOTATION</h2>
            </div>
          </div>
          <div
            v-if="quotationData.form_data"
            class="row align-items-center border-top"
          >
            <div class="col-auto">
              <h6 class="text-500">Quotation to</h6>
              <h5>{{ quotationData.form_data.customer_name }}</h5>
              <p class="fs--1">
                <a :href="`mailto:${quotationData.form_data.email}`">{{
                  quotationData.form_data.email
                }}</a
                ><br />
                <a :href="`tel:${quotationData.form_data.phone}`">{{
                  quotationData.form_data.phone
                }}</a>
              </p>
            </div>
            <div class="col-auto">
              <table class="table table-sm table-borderless fs--1 text-start">
                <tr class="alert alert-primary fw-bold text-start">
                  <th class="text-900 border" colspan="2">QUOTATION DETAILS</th>
                </tr>
                <tr>
                  <th class="text-900 border">Quotation Date:</th>
                  <td class="fw-semi-bold border">
                    {{ formatDateHuman(today) }}
                  </td>
                </tr>
                <tr class="border-top">
                  <th class="text-900 border">Quotation No:</th>
                  <td class="fw-semi-bold border">
                    QTY/{{ generateRandom(100000, 999999) }}/{{
                      today.getFullYear()
                    }}
                  </td>
                </tr>

                <tr class="border-top">
                  <th class="text-900 border">CLASS:</th>
                  <td class="fw-semi-bold border">
                    {{
                      quotationData.form_data.insurance_type == "MPC"
                        ? "Motor Private Comprehensive (MPC)"
                        : "Third Party Only (TPO)"
                    }}
                  </td>
                </tr>
                <tr class="border-top">
                  <th class="text-900 border">REG NO:</th>
                  <td class="fw-semi-bold border">
                    {{ quotationData.reg_no ? quotationData.reg_no : "-" }}
                  </td>
                </tr>
                <tr class="border-top">
                  <th class="text-900 border">SUM INSURED:</th>
                  <td class="fw-semi-bold border">
                    {{ formatToCurrency(quotationData.car_value) }}
                  </td>
                </tr>
                <tr class="border-top">
                  <th class="text-900 border">MAKE:</th>
                  <td class="fw-semi-bold border">
                    {{ quotationData.form_data.car_make }}
                  </td>
                </tr>
                <tr class="border-top">
                  <th class="text-900 border">RATE:</th>
                  <td class="fw-semi-bold border">
                    {{
                      quotationData.fields.rate
                        ? `${formatToCurrency(quotationData.fields.rate)}%`
                        : "N/A"
                    }}
                  </td>
                </tr>
                <tr class="border-top">
                  <th class="text-900 border">SUB TOTAL:</th>
                  <td class="fw-semi-bold border">
                    {{ formatToCurrency(quotationData.fields.sub_total) }}
                  </td>
                </tr>
                <tr class="border-top">
                  <th class="text-900 border">SUB TOTAL:</th>
                  <td class="fw-semi-bold border">
                    {{ formatToCurrency(quotationData.fields.sub_total) }}
                  </td>
                </tr>
                <tr class="border-top">
                  <th class="text-900 border">Excess Protector:</th>
                  <td class="fw-semi-bold border">
                    <mark>{{ getExcessProtector() }}</mark>
                  </td>
                </tr>

                <tr class="border-top">
                  <th class="text-900 border">Courtesy Car</th>
                  <td class="fw-semi-bold border">
                    <mark>{{
                      quotationData.fields.pvt
                        ? quotationData.fields.courtesy_car
                        : "No"
                    }}</mark>
                  </td>
                </tr>
                <tr class="border-top">
                  <th class="text-900 border">PVT:</th>
                  <td class="fw-semi-bold border">
                    <mark>{{
                      quotationData.fields.pvt ? quotationData.fields.pvt : "No"
                    }}</mark>
                  </td>
                </tr>
                <tr class="alert alert-success fw-bold text-start border-top">
                  <th class="text-900 border">TOTAL PREMIUM:</th>
                  <td class="fw-semi-bold border">
                    KES
                    {{
                      quotationData.amount
                        ? formatToCurrency(Math.ceil(quotationData.amount))
                        : 0
                    }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row justify-content-en">
            <div class="col-auto">
              <span class="text-mut fw-bold">Recommended Provider:</span>
              <span class="text-danger">{{ quotationData.name }}</span>
              <br />
              <br />
              Prepared by: <b>{{ user.first_name }} {{ user.last_name }}</b>
              <br />
              <span
                ><u>{{ user.role }}</u></span
              >
            </div>
            <div class="col-auto ms-auto">
              <table class="table table-sm table-borderless fs--1 text-end">
                <tr>
                  <th class="text-900 border" colspan="2">Payment Details</th>
                </tr>
                <tr>
                  <th class="text-900 border">M-PESA PAYBILL NO:</th>
                  <td class="fw-semi-bold border">
                    {{ quotationData.paybill_no }}
                  </td>
                </tr>
                <tr class="border-top">
                  <th class="text-900 border">Account Name::</th>
                  <td class="fw-semi-bold border">
                    {{ quotationData.reg_no ? quotationData.reg_no : "-" }}
                  </td>
                </tr>
                <tr class="border-top border-top-2 fw-bolder text-900">
                  <th class="border">Amount:</th>
                  <td class="border">
                    KES
                    {{
                      quotationData.amount
                        ? formatToCurrency(Math.ceil(quotationData.amount))
                        : 0
                    }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="card-footer bg-light mt-n3">
          <p class="fs--1 mb-0 bt-n4">
            <strong>Notes: </strong>We hope you will find our quotation
            competitive and look forward to receive further instructions from
            you.
          </p>
        </div>
      </div>
      <!-- End mobile -->
      <FooterComp />
    </div>
    <div
      class="modal fade"
      id="authentication-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="authentication-modal-label"
      aria-hidden="true"
    >
      <div class="modal-dialog mt-6" role="document">
        <div class="modal-content border-0">
          <div
            class="modal-header px-5 position-relative modal-shape-header bg-shape"
          >
            <div class="position-relative z-index-1 light">
              <h4 class="mb-0 text-white" id="authentication-modal-label">
                Register
              </h4>
              <p class="fs--1 mb-0 text-white">
                Please create your free Falcon account
              </p>
            </div>
            <button
              class="btn-close btn-close-white position-absolute top-0 end-0 mt-2 me-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body py-4 px-5">
            <form>
              <div class="mb-3">
                <label class="form-label" for="modal-auth-name">Name</label
                ><input
                  class="form-control"
                  type="text"
                  autocomplete="on"
                  id="modal-auth-name"
                />
              </div>
              <div class="mb-3">
                <label class="form-label" for="modal-auth-email"
                  >Email address</label
                ><input
                  class="form-control"
                  type="email"
                  autocomplete="on"
                  id="modal-auth-email"
                />
              </div>
              <div class="row gx-2">
                <div class="mb-3 col-sm-6">
                  <label class="form-label" for="modal-auth-password"
                    >Password</label
                  ><input
                    class="form-control"
                    type="password"
                    autocomplete="on"
                    id="modal-auth-password"
                  />
                </div>
                <div class="mb-3 col-sm-6">
                  <label class="form-label" for="modal-auth-confirm-password"
                    >Confirm Password</label
                  ><input
                    class="form-control"
                    type="password"
                    autocomplete="on"
                    id="modal-auth-confirm-password"
                  />
                </div>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="modal-auth-register-checkbox"
                /><label class="form-label" for="modal-auth-register-checkbox"
                  >I accept the <a href="index.html#!">terms </a>and
                  <a href="index.html#!">privacy policy</a></label
                >
              </div>
              <div class="mb-3">
                <button
                  class="btn btn-primary d-block w-100 mt-3"
                  type="submit"
                  name="submit"
                >
                  Register
                </button>
              </div>
            </form>
            <div class="position-relative mt-5">
              <hr />
              <div class="divider-content-center">or register with</div>
            </div>
            <div class="row g-2 mt-2">
              <div class="col-sm-6">
                <a
                  class="btn btn-outline-google-plus btn-sm d-block w-100"
                  href="index.html#"
                  ><span
                    class="fab fa-google-plus-g me-2"
                    data-fa-transform="grow-8"
                  ></span>
                  google</a
                >
              </div>
              <div class="col-sm-6">
                <a
                  class="btn btn-outline-facebook btn-sm d-block w-100"
                  href="index.html#"
                  ><span
                    class="fab fa-facebook-square me-2"
                    data-fa-transform="grow-8"
                  ></span>
                  facebook</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-background-color: #edf2f9;
  --easy-table-header-item-padding: 10px 15px;
}
</style>

<script>
import mixins from "../mixins/index";
import CountUp from "vue-countup-v3";
import AppBar from "@/components/AppBar.vue";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import ReportService from "../services/report.service";
import ProviderService from "../services/provider.service";
import EasyDataTable from "vue3-easy-data-table";
import SimpleTypeahead from "vue3-simple-typeahead";
import "vue3-simple-typeahead/dist/vue3-simple-typeahead.css";
import { toast } from "vue3-toastify";

export default {
  mixins: [mixins],
  components: {
    AppBar,
    CountUp,
    NavDrawer,
    FooterComp,
    EasyDataTable,
    SimpleTypeahead,
  },
  data() {
    return {
      refreshed: 0,
      data: {},
      download_url: "",
      today: new Date(),
      providers: [],
      daily_analysis: [],
      weekly_analysis: [],
      headers: [
        {
          text: "Provider",
          value: "name",
          sortable: true,
        },
        {
          text: "Type",
          value: "type",
          sortable: true,
        },
        {
          text: "Total (Ksh)",
          value: "amount",
          sortable: true,
          width: 120,
        },
      ],
      quotationData: {},
      year: new Date().getFullYear(),
      last_15_years: this.generateLastYears(15),
    };
  },
  watch: {},
  async created() {
    // this.forceReload();
    console.log(JSON.parse(atob(this.$route.params.data)));
    this.quotationData = JSON.parse(atob(this.$route.params.data));
    await this.setFormData();
    await this.getQuotationsData();
    this.quotationData.username =
      this.user.first_name + " " + this.user.last_name;
    this.quotationData.user_role = this.user.role;
    await this.generateDownloadUrl();
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
    computed_download_url() {
      return this.download_url;
    },
  },
  methods: {
    getExcessProtector() {
      let labelText = "";
      let selected_xp = this.quotationData.fields.excess_protector;
      let provider_id = this.quotationData.provider_id;
      let car_value = this.quotationData.car_value;
      switch (true) {
        case provider_id == 9 && car_value < 1000000:
          labelText = "Not offered";
          break;
        case provider_id == 18 && car_value < 1500000:
          labelText = "Not offered";
          break;
        default:
          if (selected_xp) {
            labelText = "Yes";
          } else {
            labelText = "No";
          }
          break;
      }
      return labelText;
    },
    uploadQuotation() {
      toast.info("Quotation saved");
    },
    setFormData() {
      this.quotationData.id = this.quotationData.premium_provider.id;
      this.quotationData.amount = this.quotationData.premium_provider.amount;
      this.quotationData.name = this.quotationData.premium_provider.name;
      this.quotationData.type = this.quotationData.premium_provider.type;
      this.quotationData.car_make = this.quotationData.form_data.car_make;
      this.quotationData.car_value = this.quotationData.form_data.car_value;
      this.quotationData.courtesy_car =
        this.quotationData.form_data.courtesy_car;
      this.quotationData.courtesy_car =
        this.quotationData.form_data.courtesy_car;
      this.quotationData.courtesy_car =
        this.quotationData.form_data.courtesy_car;
      this.quotationData.excess_protector =
        this.quotationData.form_data.excess_protector;
      this.quotationData.insurance_type =
        this.quotationData.form_data.insurance_type;
      this.quotationData.pvt = this.quotationData.form_data.pvt;
      this.quotationData.reg_no = this.quotationData.form_data.reg_no;
      this.quotationData.yom = this.quotationData.form_data.yom;
    },
    generateReport() {
      var element = document.getElementById("element-to-print");
      html2pdf(element, {
        margin: 0.1,
        filename: `JIA Quotation ${this.quotationData.form_data.customer_name}.pdf`,
        pagebreak: { after: ".sautDePage" },
        image: {
          type: "jpeg",
          quality: 2,
        },
        html2canvas: {
          scale: 2,
          letterRendering: true,
        },
        jsPDF: {
          unit: "in",
          format: "a4",
          orientation: "landscape",
        },
      });
    },
    async generateDownloadUrl() {
      this.$store.commit("SET_LOADING", true);
      let plaintext = JSON.stringify(this.quotationData);
      this.quotationData.excess_protector = this.getExcessProtector();
      this.quotationData.username =
        this.user.first_name + " " + this.user.last_name;
      this.quotationData.user_role = this.user.role;
      let base_64 = await window.btoa(plaintext);
      this.download_url =
        process.env.VUE_APP_BASE_URL + "quotation-download/" + base_64;
      console.group("Running...");
      console.log(this.download_url);
      this.$store.commit("SET_LOADING", false);
    },
    getQuotationsData() {
      this.$store.commit("SET_LOADING", true);
      return ProviderService.quotationData(this.quotationData).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            this.quotationData.fields = response.data.data.fields;
            this.quotationData.name = response.data.data.name;
            this.quotationData.type = response.data.data.type;
            this.quotationData.paybill_no = response.data.data.paybill_no;
            this.quotationData.provider_id = response.data.data.provider_id;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
