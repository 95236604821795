<template>
  <div>
    <div
      class="card mb-3"
      id="ordersTable"
      data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'
    >
      <div class="card-header">
        <div class="row flex-between-center border-bottom pb-1">
          <div class="col-4 col-sm-auto align-items-center pe-0">
            <h5 class="fs-0 mb-0 text-nowrap py- py-xl-0">
              <i class="fa fa-users text-warning"></i>
              Promote Students
            </h5>
            <p class="fs--1 fw-1 text-500 mb-0 text-nowrap">
              Manage all available students
            </p>
          </div>
        </div>
      </div>
      <div class="card-body px-3 py-0">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4 col-xl-4">
            <div class="input-group mb-2 h1">
              <input
                class="form-control shadow-none search me-auto"
                type="search"
                placeholder="Search..."
                aria-label="search"
                v-model="search_term"
                v-on:keyup="searchItem"
              />
              <div class="input-group-text bg-transparent">
                <span class="fa fa-search fs--1 text-600"></span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-8 col-xl-8 text-end">
            <button
              class="btn btn-falcon-warning btn-sm ms-2 h2 rounded-pill"
              type="button"
              @click="listView"
            >
              <span class="fas fa-barcode" data-fa-transform=""></span
              ><span class="d-none d-sm-inline-block ms-1">List view</span>
            </button>
          </div>
        </div>
        <!-- List view table  -->
        <div v-if="list_view" class="table-responsive scrollbar">
          <table
            class="table table-sm table-striped fs--1 mb-0 overflow-hidden"
          >
            <thead class="bg-200 text-900">
              <tr>
                <th
                  class="sort pe-1 align-middle white-space-nowrap"
                  style="width: 5%"
                >
                  <div class="form-check fs-0 mb-0 d-flex align-items-center">
                    <input
                      class="form-check-input"
                      id="checkbox-bulk-customers-select"
                      type="checkbox"
                      data-bulk-select='{"body":"table-orders-body","actions":"orders-bulk-actions","replacedElement":"orders-actions"}'
                    />
                  </div>
                </th>
                <!-- <th
                  class="sort pe-1 align-middle white-space-nowrap"
                  data-sort="order"
                  style="width: 5%"
                >
                  Auto #
                </th> -->
                <th
                  class="sort pe-1 align-middle white-space-nowrap"
                  data-sort="order"
                  style="width: 5%"
                >
                  Photo
                </th>
                <th
                  class="sort pe-1 align-middle white-space-nowrap"
                  data-sort="order"
                  style="width: 10%"
                >
                  Adm No.
                </th>
                <th
                  class="sort pe-1 align-middle white-space-nowrap"
                  data-sort="order"
                  style="width: 20%"
                >
                  Full Name
                </th>
                <th
                  class="sort pe-1 align-middle white-space-nowrap pe-7"
                  data-sort="date"
                  @click="sortBy('date')"
                  style="width: 10%"
                >
                  Phone
                </th>
                <!-- <th
                  class="sort pe-1 align-middle white-space-nowrap"
                  data-sort="status"
                  style="width: 10%"
                >
                  Postal Address
                </th> -->
                <!-- <th
                  class="sort pe-1 align-middle white-space-nowrap"
                  data-sort="status"
                  style="width: 10%"
                >
                  County
                </th>
                <th
                  class="sort pe-1 align-middle white-space-nowrap"
                  data-sort="status"
                  style="width: 10%"
                >
                  Town
                </th> -->
                <th
                  class="sort pe-1 align-middle white-space-nowrap"
                  data-sort="status"
                  style="width: 10%"
                >
                  Gender
                </th>
                <th
                  class="sort pe-1 align-middle white-space-nowrap"
                  data-sort="status"
                  style="width: 40%"
                >
                  Class
                </th>
                <th
                  class="sort pe-1 ml-auto white-space-nowrap text-center"
                  data-sort="amount"
                  style="width: 10%"
                >
                  Actions
                </th>
                <th class="no-sort"></th>
              </tr>
            </thead>
            <tbody
              :key="students_table_key"
              class="list"
              id="table-orders-body"
            >
              <tr
                class="btn-reveal-trigger"
                v-for="(student, i) in studentsData"
                :key="i"
              >
                <td class="align-middle" style="width: 28px">
                  <div class="form-check fs-0 mb-0 d-flex align-items-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="checkbox-0"
                      :value="student.id"
                      @click="updateSelected"
                      data-bulk-select-row="data-bulk-select-row"
                    />
                  </div>
                </td>
                <td class="address py-2 align-middle white-space-nowrap">
                  <div class="avatar avatar-2xl status-online">
                    <img
                      class="rounded-circle"
                      :src="getImageUrl(student.image)"
                      alt=""
                    />
                  </div>
                </td>
                <td class="address py-2 align-middle white-space-nowrap">
                  {{ student.admission_number }}
                </td>
                <td class="order py-2 align-middle white-space-nowrap">
                  <strong
                    >{{ student.first_name }} {{ student.last_name }}</strong
                  >
                </td>
                <td class="address py-2 align-middle white-space-nowrap">
                  {{ student.phone }}
                </td>
                <td class="date py-2 align-middle">
                  {{
                    student.gender ? student.gender.toUpperCase() : "Not set"
                  }}
                </td>
                <td class="date py-2 align-middle">
                  {{ student.form ? student.form.name : "Not set" }}
                </td>
                <td class="py-2 align-end white-space-nowrap">
                  <button
                    class="btn btn-falcon-warning btn-sm ms-2 h2 rounded-pill"
                    type="button"
                    @click="promoteStudent(student)"
                  >
                    <span class="d-none d-sm-inline-block ms-1">Promote</span>
                    <span
                      class="fas fa-arrow-right ms-1"
                      data-fa-transform=""
                    ></span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Cards view table  -->
        <div v-else>
          <hr class="my-1" />
          <div class="row g-3">
            <div class="col-sm-2" v-for="(element, i) in studentsData" :key="i">
              <div class="card overflow-hidden" style="min-width: 3rem">
                <div
                  class="bg-holder bg-card"
                  style="
                    background-image: url('../assets/img/icons/spot-illustrations/corner-2.png');
                  "
                ></div>
                <div class="card-body position-relative">
                  <h6 class="border-bottom text-center text-nowrap">
                    {{
                      element.first_name ? element.first_name.toUpperCase() : ""
                    }}
                    {{
                      element.last_name ? element.last_name.toUpperCase() : ""
                    }}
                  </h6>
                  <div class="mx-auto avatar avatar-4xl d-flex">
                    <img
                      class="rounded-circle"
                      :src="getImageUrl(element.image)"
                      alt="photo"
                    />
                  </div>
                  <div class="d-flex">
                    <span class="mx-auto">{{ element.admission_number }}</span>
                  </div>
                  <div class="d-flex">
                    <span class="mx-auto fs--2 fw-1 text-500 mb-0 text-nowrap">
                      {{ element.form ? element.form.name : "N/S" }}
                    </span>
                  </div>
                  <div class="d-flex my-2">
                    <div class="mx-auto">
                      <button
                        class="btn btn-sm btn-falcon-info rounded-circle py-1 px-2 me-1"
                        type="button"
                      >
                        <i class="fa fa-eye"></i>
                      </button>
                      <button
                        class="btn btn-sm btn-falcon-primary rounded-circle py-1 px-2 me-1"
                        type="button"
                        @click="goToAdmission(element)"
                      >
                        <i class="fa fa-pen"></i>
                      </button>
                      <button
                        class="btn btn-sm btn-falcon-danger rounded-circle py-1 px-2"
                        type="button"
                        @click="deleteResource(element)"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="d-flex justify-content-center mt-3">
          <pagination
            v-model="current_page"
            :records="records"
            :per-page="per_page"
            @paginate="myCallback"
          />
        </div>
      </div>
    </div>
    <!-- Start edit modal  -->
    <div
      class="modal fade"
      id="edit-modal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="edit-modalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">Edit Customer</h4>
            </div>
            <div class="row my-3 mx-2">
              <div class="col-sm-12 col-md-12 col-lg-9 col-xl-9">
                <div class="d-flex">
                  <span class="fa-stack ms-n1 me-3"
                    ><i class="fas fa-circle fa-stack-2x text-200"></i
                    ><i
                      class="fa-inverse fa-stack-1x text-primary fas fa-tag"
                      data-fa-transform="shrink-2"
                    ></i
                  ></span>
                  <div class="flex-1">
                    <div class="row">
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="recipient-name"
                          >FirstName</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          v-model="formData.first_name"
                          :class="
                            v$.formData.first_name.$dirty &&
                            v$.formData.first_name.$invalid
                              ? `is-invalid`
                              : v$.formData.first_name.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.first_name.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="recipient-name"
                          >Last Name</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          v-model="formData.last_name"
                          :class="
                            v$.formData.last_name.$dirty &&
                            v$.formData.last_name.$invalid
                              ? `is-invalid`
                              : v$.formData.last_name.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.last_name.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="message-text"
                          >Email</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          v-model="formData.email"
                        />
                      </div>
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="message-text"
                          >Phone</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          disabled
                          v-model="formData.mobile_no"
                        />
                      </div>
                    </div>
                    <hr class="my-2" />
                  </div>
                </div>
                <div class="d-flex">
                  <span class="fa-stack ms-n1 me-3"
                    ><i class="fas fa-circle fa-stack-2x text-200"></i
                    ><i
                      class="fa-inverse fa-stack-1x text-primary fas fa-align-left"
                      data-fa-transform="shrink-2"
                    ></i
                  ></span>
                  <div class="flex-1">
                    <!-- <h5 class="mb-2 fs-0">KYCs</h5> -->
                    <div class="row">
                      <div class="mb-3 col-12">
                        <label class="col-form-label" for="recipient-name"
                          >KRA PIN:</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="file"
                        />
                      </div>
                      <div class="mb-2 col-12">
                        <label class="col-form-label" for="recipient-name"
                          >Log Book</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="file"
                        />
                      </div>
                      <div class="mb-2 col-12">
                        <label class="col-form-label" for="recipient-name"
                          >National ID</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="file"
                        />
                      </div>
                      <label class="col-form-label" for="message-text"
                        >Other details</label
                      >
                      <p class="text-word-break fs--1">
                        <textarea
                          v-model="formData.detail"
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                        ></textarea>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                <h6 class="mt-5 mt-lg-0">Vehicles</h6>
                <ul class="nav flex-lg-column fs--1">
                  <li
                    class="nav-item me-2 me-lg-0"
                    v-for="(vehicle, i) in formData.vehicles"
                    :key="i"
                  >
                    <a
                      @click="toggleActive"
                      class="nav-link nav-link-card-details text-warning"
                      role="button"
                      ><span class="fas fa-dot-circle me-2"></span
                      >{{ vehicle.make }}, {{ vehicle.model }} -
                      {{ vehicle.reg_no }}
                    </a>
                  </li>
                </ul>
                <hr />
                <h6 class="mt-5 mt-lg-0">Other Actions</h6>
                <ul class="nav flex-lg-column fs--1">
                  <li class="nav-item me-2 me-lg-0">
                    <a
                      @click="toggleActive"
                      class="nav-link nav-link-card-details"
                      role="button"
                      ><span class="fa fa-plus me-2"></span>Add Vehicle
                    </a>
                  </li>
                  <li class="nav-item me-2 me-lg-0">
                    <a
                      @click="toggleRoot"
                      class="nav-link nav-link-card-details"
                      role="button"
                    >
                      <span class="fas fa-download me-2"></span>Download KYCs
                    </a>
                  </li>
                  <li class="nav-item me-2 me-lg-0">
                    <a
                      class="nav-link nav-link-card-details"
                      role="button"
                      @click="toggleShowOnMain"
                      ><span class="fas fa-align-left me-2"></span>View
                      History</a
                    >
                  </li>
                  <li class="nav-item me-2 me-lg-0 mt-8">
                    <a
                      class="nav-link nav-link-card-details bg-danger text-white"
                      role="button"
                      @click="deleteResource(formData)"
                      ><span class="fas fa-paperclip me-2"></span>Delete</a
                    >
                  </li>
                  <li class="nav-item me-2 me-lg-0 mt-2">
                    <a
                      class="nav-link nav-link-card-details bg-primary text-white"
                      role="button"
                      @click="updateResource"
                      ><span class="fa fa-align-left me-2"></span>Update
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End edit modal  -->
    </div>
    <!-- Start add modal  -->
    <div
      class="modal fade"
      id="add-modal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">New</h4>
            </div>
            <div class="p-4">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-lg-9 col-xl-9">
                  <div class="d-flex">
                    <span class="fa-stack ms-n1 me-3"
                      ><i class="fas fa-circle fa-stack-2x text-200"></i
                      ><i
                        class="fa-inverse fa-stack-1x text-primary fas fa-tag"
                        data-fa-transform="shrink-2"
                      ></i
                    ></span>
                    <div class="flex-1">
                      <div class="row">
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="recipient-name"
                            >First Name</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            placeholder="e.g John Doe"
                            v-model="formData.first_name"
                            :class="
                              v$.formData.first_name.$dirty &&
                              v$.formData.first_name.$invalid
                                ? `is-invalid`
                                : v$.formData.first_name.$dirty
                                ? `is-valid`
                                : ``
                            "
                          />
                          <div
                            v-for="error of v$.formData.first_name.$errors"
                            :key="error.$uid"
                            class="invalid-feedback"
                            style="color: red"
                          >
                            This field is invalid
                          </div>
                        </div>
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="recipient-name"
                            >Last Name</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            placeholder="e.g John Doe"
                            v-model="formData.last_name"
                            :class="
                              v$.formData.last_name.$dirty &&
                              v$.formData.last_name.$invalid
                                ? `is-invalid`
                                : v$.formData.last_name.$dirty
                                ? `is-valid`
                                : ``
                            "
                          />
                          <div
                            v-for="error of v$.formData.last_name.$errors"
                            :key="error.$uid"
                            class="invalid-feedback"
                            style="color: red"
                          >
                            This field is invalid
                          </div>
                        </div>
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="message-text"
                            >Email</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            placeholder="e.g johndoe@gmail.com"
                            v-model="formData.email"
                          />
                        </div>
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="message-text"
                            >Phone</label
                          >
                          <input
                            class="form-control"
                            id="recipient-home"
                            type="text"
                            placeholder="e.g +25471234567"
                            v-model="formData.phone"
                          />
                        </div>
                      </div>
                      <hr class="my-2" />
                    </div>
                  </div>
                  <div class="d-flex">
                    <span class="fa-stack ms-n1 me-3"
                      ><i class="fas fa-circle fa-stack-2x text-200"></i
                      ><i
                        class="fa-inverse fa-stack-1x text-primary fas fa-align-left"
                        data-fa-transform="shrink-2"
                      ></i
                    ></span>
                    <div class="flex-1">
                      <!-- <h5 class="mb-2 fs-0">KYCs</h5> -->
                      <div class="row">
                        <div class="mb-3 col-12">
                          <label class="col-form-label" for="recipient-name"
                            >KRA PIN:</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="file"
                          />
                        </div>
                        <div class="mb-2 col-12">
                          <label class="col-form-label" for="recipient-name"
                            >Log Book</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="file"
                          />
                        </div>
                        <div class="mb-2 col-12">
                          <label class="col-form-label" for="recipient-name"
                            >National ID</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="file"
                          />
                        </div>
                        <label class="col-form-label" for="message-text"
                          >Other details</label
                        >
                        <p class="text-word-break fs--1">
                          <textarea
                            v-model="formData.detail"
                            class="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            :placeholder="formData.detail"
                          ></textarea>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                  <h6 class="mt-5 mt-lg-0">Other Actions</h6>
                  <ul class="nav flex-lg-column fs--1">
                    <li class="nav-item me-2 me-lg-0">
                      <a
                        @click="toggleActive"
                        class="nav-link nav-link-card-details"
                        role="button"
                        :class="formData.active ? `bg-info text-white` : ``"
                        ><span class="fa fa-check me-2"></span>Active?
                      </a>
                    </li>
                    <li class="nav-item me-2 me-lg-0">
                      <a
                        @click="toggleRoot"
                        class="nav-link nav-link-card-details"
                        role="button"
                        :class="formData.is_root ? `bg-info text-white` : ``"
                      >
                        <span class="fas fa-user me-2"></span>Root/Parent?
                      </a>
                    </li>
                    <li class="nav-item me-2 me-lg-0">
                      <a
                        @click="toggleShowOnMain"
                        class="nav-link nav-link-card-details"
                        role="button"
                        ><span class="fas fa-align-left me-2"></span>Show on
                        Drawer</a
                      >
                    </li>
                    <br />
                    <hr />
                    <li class="nav-item me-2 me-lg-0 mt-auto">
                      <a
                        class="btn btn-primary nav-link nav-link-card-details"
                        role="button"
                        @click="saveResource"
                        ><span class="fa fa-save me-2"></span>Save
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End add modal  -->
    <!-- Start add modal  -->
    <div
      class="modal fade"
      id="import-modal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">Import</h4>
            </div>
            <div class="p-4">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-lg-9 col-xl-9">
                  <div class="d-flex">
                    <span class="fa-stack ms-n1 me-3"
                      ><i class="fas fa-circle fa-stack-2x text-200"></i
                      ><i
                        class="fa-inverse fa-stack-1x text-primary fas fa-tag"
                        data-fa-transform="shrink-2"
                      ></i
                    ></span>
                    <div class="flex-1">
                      <div class="row">
                        <div class="mb-3 col-12">
                          <label
                            class="col-form-label text-decoration-underline text-primary"
                            for="recipient-name"
                            role="button"
                            :href="sampleCsvUrl"
                            >Download sample csv file</label
                          >
                        </div>
                      </div>
                      <hr class="my-2" />
                    </div>
                  </div>
                  <div class="d-flex">
                    <span class="fa-stack ms-n1 me-3"
                      ><i class="fas fa-circle fa-stack-2x text-200"></i
                      ><i
                        class="fa-inverse fa-stack-1x text-primary fas fa-align-left"
                        data-fa-transform="shrink-2"
                      ></i
                    ></span>
                    <div class="flex-1">
                      <!-- <h5 class="mb-2 fs-0">KYCs</h5> -->
                      <div class="row">
                        <div class="mb-3 col-12">
                          <label class="col-form-label" for="recipient-name"
                            >Upload file</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="file"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                  <h6 class="mt-5 mt-lg-0">Other Actions</h6>
                  <ul class="nav flex-lg-column fs--1">
                    <li class="nav-item me-2 me-lg-0">
                      <a
                        @click="toggleShowOnMain"
                        class="nav-link nav-link-card-details"
                        role="button"
                        ><span class="fas fa-align-left me-2"></span>Import
                        history</a
                      >
                    </li>
                    <br />
                    <hr />
                    <li class="nav-item me-2 me-lg-0 mt-auto">
                      <a
                        class="btn btn-sm btn-falcon-warning rounded-pill"
                        role="button"
                        @click="saveResource"
                        ><span class="fa fa-upload me-2"></span>Upload
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End add modal  -->
  </div>
</template>

<script>
import $ from "jquery";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import "flatpickr/dist/flatpickr.css";
import AppBar from "@/components/AppBar.vue";
import { useVuelidate } from "@vuelidate/core";
import flatPickr from "vue-flatpickr-component";
import EasyDataTable from "vue3-easy-data-table";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import CustomerService from "../services/customer.service";
import { required, email } from "@vuelidate/validators";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import StudentService from "../services/student.service";
import GuardianService from "../services/guardian.service";
import FormsService from "../services/class.service";
import StreamService from "../services/stream.service";
import Pagination from "v-pagination-3";

const now = new Date();

export default {
  mixins: [mixins],
  name: "CustomersView",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      formData: {
        first_name: { required },
        last_name: { required },
        phone: { required },
        email: { required, email },
      },
    };
  },
  components: {
    NavDrawer,
    AppBar,
    flatPickr,
    Pagination,
    FooterComp,
    LoaderOverlay,
    EasyDataTable,
  },
  data() {
    return {
      search_term: "",
      per_page: 18,
      list_view: true,
      studentsData: [],
      searchValue: "",
      table_keys: 1,
      records: 0,
      current_page: 1,
      formData: {
        is_root: 0,
        active: 0,
        show_on_main_page: 0,
      },
      resources: [],
      orders_table_key: 0,
    };
  },
  created() {
    this.getStudents();
  },
  watch: {
    resources(newVal, oldVal) {
      console.log("resource length", newVal.length);
      this.orders_table_key += 1;
    },
  },
  computed: {
    loading() {
      return this.$store.gtters.getLoading;
    },
    theme() {
      return localStorage.getItem("theme");
    },
    sampleCsvUrl() {
      return process.env.VUE_APP_BASE_URL + "student-sample-csv";
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    promoteStudent(item) {
      console.log(item);
      let sure = confirm(
        "Are you sure you want to promote? you cannot undo this action."
      );
      if (sure) {
      }
    },
    myCallback(e) {
      this.getStudentsPage(e);
    },
    listView() {
      this.list_view = !this.list_view;
    },
    getImageUrl(name) {
      if (name) {
        return process.env.VUE_APP_API_URL + name;
      } else {
        return "assets/img/team/default.png";
      }
    },
    searchItem() {
      this.current_page = 1;
      this.page = 1;
      if (this.search_term) {
        this.$store.commit("SET_LOADING", true);
        StudentService.search(this.search_term).then(
          (response) => {
            this.records = response.data.data.total;
            this.studentsData = response.data.data.data;
            this.current_page = response.data.data.current_page;
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      } else {
        this.getStudents();
      }
    },
    goToAdmission(student) {
      this.$router.push("/new-admission/" + student.id);
    },
    goToNewAdmission() {
      this.$router.push("/new-admission");
    },
    getAllGuardian() {
      GuardianService.index().then(
        (response) => {
          let result = response.data.data;
          this.guardianOptions = result.map(function (element) {
            let data = {
              value: element.id,
              label:
                element.name +
                ", ID:" +
                element.national_id +
                ", Location :" +
                element.location,
            };
            return data;
          });
          this.guardianOptions.unshift({
            value: "",
            label: "-- Select guardian --",
          });
        },
        (error) => {
          toast.error({
            title: "Error",
            message: "Error getting students data",
          });
        }
      );
    },
    getStudents() {
      this.$store.commit("SET_LOADING", true);
      StudentService.index().then(
        (response) => {
          this.records = response.data.data.total;
          this.studentsData = response.data.data.data;
          this.current_page = response.data.data.current_page;
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          console.log(error);
          toast.error({
            title: "Error",
            message: "Error getting user data",
          });
        }
      );
    },
    getStudentsPage(page) {
      this.$store.commit("SET_LOADING", true);
      StudentService.byPage(page).then(
        (response) => {
          this.records = response.data.data.total;
          this.studentsData = response.data.data.data;
          this.current_page = response.data.data.current_page;
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          console.log(error);
          toast.error({
            title: "Error",
            message: "Error getting user data",
          });
        }
      );
    },
    getAllClasses() {
      FormsService.index().then(
        (response) => {
          let result = response.data.data;
          this.formOptions = result.map(function (element) {
            let data = {
              value: element.id,
              label: element.name,
            };
            return data;
          });
          // this.formOptions.unshift({ value: "", label: "Select class" });
        },
        (error) => {
          toast.error({
            title: "Error",
            message: "Error getting user data",
          });
        }
      );
    },
    getAllStream() {
      StreamService.index().then(
        (response) => {
          let result = response.data.data;
          this.streamOptions = result.map(function (element) {
            let data = {
              value: element.id,
              label: element.name,
            };
            return data;
          });
          this.streamOptions.unshift({
            value: "",
            label: "-- Select stream --",
          });
        },
        (error) => {
          toast.error({
            title: "Error",
            message: "Error getting user data",
          });
        }
      );
    },
    editItem(item) {
      this.formData = item;
    },
    toggleActive() {
      this.formData.active = !this.formData.active;
    },
    toggleRoot() {
      this.formData.is_root = !this.formData.is_root;
    },
    saveResource() {
      this.v$.formData.title.$touch();
      this.v$.formData.link.$touch();
      if (this.v$.formData.$invalid) {
        return;
      }
      this.$store.commit("SET_LOADING", true);
      CustomerService.store(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            // Clear modals
            $("#add-modal").hide();
            $("body").removeClass("modal-open");
            $(".modal-backdrop").remove();

            this.table_keys += 1;
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getStudents();
          } else {
            console.group("customer");
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          toast.error(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    updateResource() {
      this.$store.commit("SET_LOADING", true);
      CustomerService.update(this.formData).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getStudents();
            // Clear modals
            $("#edit-modal").removeClass("fade").hide();
            $(".modal-backdrop").remove();
          } else {
            this.resources = [];
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    deleteResource(formData) {
      let sure = confirm(
        "Are you sure you want to delete? you cannot undo this action."
      );
      this.$store.commit("SET_LOADING", true);
      if (sure) {
        StudentService.delete(formData.id).then(
          (response) => {
            console.log(response);
            if (response.data.code == 200) {
              toast.success(response.data.message, {
                autoClose: 2000,
              });
              this.getStudents();
            } else {
              console.log(response.data.error);
              toast.error(response.data.error, {
                autoClose: 2000,
              });
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      } else {
        this.$store.commit("SET_LOADING", false);
      }
    },
  },
};
</script>
