<template>
  <div>
    <div
      class="card mb-3"
      id="ordersTable"
      data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'
    >
      <div class="card-header">
        <div class="row flex-between-center border-bottom pb-1">
          <div class="col-4 col-sm-auto align-items-center pe-0">
            <h5 class="fs-0 mb-0 text-nowrap py- py-xl-0">
              <i class="fa fa-users text-warning"></i>
              Classes
            </h5>
            <p class="fs--1 fw-1 text-500 mb-0 text-nowrap">
              Manage all classes
            </p>
          </div>
        </div>
      </div>
      <div class="card-body px-3 py-0">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4 col-xl-4">
            <div class="input-group mb-2 h1">
              <input
                class="form-control shadow-none search me-auto"
                type="search"
                placeholder="Search..."
                aria-label="search"
                v-model="search_term"
                v-on:keyup="searchItem"
              />
              <div class="input-group-text bg-transparent">
                <span class="fa fa-search fs--1 text-600"></span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-8 col-xl-8 text-end">
            <button
              class="btn btn-falcon-warning btn-sm ms-2 h2 rounded-pill"
              type="button"
              @click="listView"
            >
              <span class="fas fa-barcode" data-fa-transform=""></span
              ><span class="d-none d-sm-inline-block ms-1">List view</span>
            </button>
            <button
              class="btn btn-falcon-warning btn-sm ms-2 h2 rounded-pill"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#add-modal"
              @click="addItem()"
            >
              <span class="fas fa-user-plus" data-fa-transform=""></span
              ><span class="d-none d-sm-inline-block ms-1">New</span>
            </button>
            <button
              class="btn btn-falcon-warning btn-sm ms-2 rounded-pill h1"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#import-modal"
            >
              <span class="fas fa-file-excel" data-fa-transform=""></span
              ><span class="d-none d-sm-inline-block ms-1">Import</span>
            </button>
            <button
              class="btn btn-falcon-warning btn-sm ms-2 rounded-pill h1"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#export-modal"
            >
              <span class="fas fa-file-excel" data-fa-transform=""></span
              ><span class="d-none d-sm-inline-block ms-1">Export</span>
            </button>
          </div>
        </div>
        <!-- List view table  -->
        <div v-if="list_view" class="table-responsive scrollbar">
          <table
            class="table table-sm table-striped fs--1 mb-0 overflow-hidden"
          >
            <thead class="bg-200 text-900">
              <tr>
                <th
                  class="sort pe-1 align-middle white-space-nowrap"
                  style="width: 5%"
                >
                  <div class="form-check fs-0 mb-0 d-flex align-items-center">
                    <input
                      class="form-check-input"
                      id="checkbox-bulk-customers-select"
                      type="checkbox"
                      data-bulk-select='{"body":"table-orders-body","actions":"orders-bulk-actions","replacedElement":"orders-actions"}'
                    />
                  </div>
                </th>
                <th
                  class="sort pe-1 align-middle white-space-nowrap"
                  data-sort="order"
                  style="width: 5%"
                >
                  Auto #
                </th>
                <th
                  class="sort pe-1 align-middle white-space-nowrap"
                  data-sort="order"
                  style="width: 50%"
                >
                  Name
                </th>
                <th
                  class="sort pe-1 align-middle white-space-nowrap pe-7"
                  data-sort="date"
                  @click="sortBy('date')"
                  style="width: 30%"
                >
                  Category
                </th>
                <th
                  class="sort pe-1 ml-auto white-space-nowrap text-end"
                  data-sort="amount"
                  style="width: 10%"
                >
                  Actions
                </th>
                <th class="no-sort"></th>
              </tr>
            </thead>
            <tbody
              :key="students_table_key"
              class="list"
              id="table-orders-body"
            >
              <tr
                class="btn-reveal-trigger"
                v-for="(student, i) in resources"
                :key="i"
              >
                <td class="align-middle" style="width: 28px">
                  <div class="form-check fs-0 mb-0 d-flex align-items-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="checkbox-0"
                      :value="student.id"
                      @click="updateSelected"
                      data-bulk-select-row="data-bulk-select-row"
                    />
                  </div>
                </td>
                <td class="order py-2 align-middle white-space-nowrap">
                  <strong>{{ student.id }}</strong>
                </td>
                <td class="address py-2 align-middle white-space-nowrap">
                  {{ student.name }}
                </td>
                <td class="date py-2 align-middle">
                  {{ student.description }}
                </td>
                <td class="py-2 align-middle white-space-nowrap text-end">
                  <div class="dropdown font-sans-serif position-static">
                    <button
                      class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                      type="button"
                      id="order-dropdown-0"
                      data-bs-toggle="dropdown"
                      data-boundary="viewport"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span class="fas fa-ellipsis-h fs--1"></span>
                    </button>
                    <div
                      class="dropdown-menu dropdown-menu-end border py-0"
                      aria-labelledby="order-dropdown-0"
                    >
                      <div class="py-2">
                        <a
                          class="dropdown-item text-primary py-2"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#edit-modal"
                          @click="editItem(student)"
                        >
                          <i class="fa fa-edit"></i>
                          Edit
                        </a>
                        <a
                          @click="deleteResource(student)"
                          class="dropdown-item text-danger py-2"
                          href="javascript:;"
                        >
                          <i class="fa fa-trash"></i>
                          Delete</a
                        >
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Cards view table  -->
        <div v-else>
          <hr />
          <div class="row g-3">
            <div class="col-sm-3" v-for="(element, i) in resources" :key="i">
              <div  @click="goToClass(element)" class="card overflow-hidden" style="min-width: 12rem">
                <div
                  class="bg-holder bg-card"
                  style="
                    background-image: url('../assets/img/icons/spot-illustrations/corner-2.png');
                  "
                ></div>
                <div class="card-body position-relative">
                  <h6>{{ element.name }}</h6>
                  <div class="d-flex">
                    <count-up
                      class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-info"
                      :end-val="element.students ? element.students.length : 0"
                      :start-val="0"
                    ></count-up>
                    <i class="ms-auto fa fa-graduation-cap h1"></i>
                  </div>
                  <a
                    class="fw-semi-bold fs--1 text-nowrap"
                    href="../app/e-commerce/orders/order-list.html"
                    >Students<span
                      class="fas fa-angle-right ms-1"
                      data-fa-transform="down-1"
                    ></span
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="d-flex justify-content-center mt-3">
          <pagination
            v-model="current_page"
            :records="records"
            :per-page="per_page"
            @paginate="myCallback"
          />
        </div>
      </div>
    </div>
    <!-- Start edit modal  -->
    <div
      class="modal fade"
      id="edit-modal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="edit-modalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">Edit</h4>
            </div>
            <div class="row my-3 mx-2">
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="d-flex">
                  <span class="fa-stack ms-n1 me-3"
                    ><i class="fas fa-circle fa-stack-2x text-200"></i
                    ><i
                      class="fa-inverse fa-stack-1x text-primary fas fa-tag"
                      data-fa-transform="shrink-2"
                    ></i
                  ></span>
                  <div class="flex-1">
                    <div class="row">
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="recipient-name"
                          >Name</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          v-model="formData.name"
                          :class="
                            v$.formData.name.$dirty && v$.formData.name.$invalid
                              ? `is-invalid`
                              : v$.formData.name.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.name.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="recipient-name"
                          >Code</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          v-model="formData.code"
                          :class="
                            v$.formData.code.$dirty && v$.formData.code.$invalid
                              ? `is-invalid`
                              : v$.formData.code.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.code.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>
                      <div class="mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label class="col-form-label" for="message-text"
                          >Category</label
                        >
                        <textarea
                          v-model="formData.description"
                          class="form-select"
                          aria-label="Select course type"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer p-">
            <button
              class="btn btn-warning"
              role="button"
              @click="updateResource"
            >
              <span class="fa fa-check-square me-2"></span>Update
            </button>
          </div>
        </div>
      </div>
      <!-- End edit modal  -->
    </div>
    <!-- Start add modal  -->
    <div
      class="modal fade"
      id="add-modal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="add-modalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">New</h4>
            </div>
            <div class="row my-3 mx-2">
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="d-flex">
                  <span class="fa-stack ms-n1 me-3"
                    ><i class="fas fa-circle fa-stack-2x text-200"></i
                    ><i
                      class="fa-inverse fa-stack-1x text-primary fas fa-tag"
                      data-fa-transform="shrink-2"
                    ></i
                  ></span>
                  <div class="flex-1">
                    <div class="row">
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="recipient-name"
                          >Name</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          v-model="formData.name"
                          :class="
                            v$.formData.name.$dirty && v$.formData.name.$invalid
                              ? `is-invalid`
                              : v$.formData.name.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.name.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="recipient-name"
                          >Code</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          v-model="formData.code"
                          :class="
                            v$.formData.code.$dirty && v$.formData.code.$invalid
                              ? `is-invalid`
                              : v$.formData.code.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.code.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>
                      <div class="mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label class="col-form-label" for="message-text"
                          >Category</label
                        >
                        <textarea
                          v-model="formData.description"
                          class="form-select"
                          aria-label="Select course type"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer p-">
            <button class="btn btn-warning" role="button" @click="saveResource">
              <span class="fa fa-check-square me-2"></span>Save
            </button>
          </div>
        </div>
      </div>
      <!-- End edit modal  -->
    </div>
    <!-- End add modal  -->

    <!-- Start import modal  -->
    <div
      class="modal fade"
      id="import-modal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">Import</h4>
            </div>
            <div class="p-4">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-lg-9 col-xl-9">
                  <div class="d-flex">
                    <span class="fa-stack ms-n1 me-3"
                      ><i class="fas fa-circle fa-stack-2x text-200"></i
                      ><i
                        class="fa-inverse fa-stack-1x text-primary fas fa-tag"
                        data-fa-transform="shrink-2"
                      ></i
                    ></span>
                    <div class="flex-1">
                      <div class="row">
                        <div class="mb-3 col-12">
                          <label
                            class="col-form-label text-decoration-underline text-primary"
                            for="recipient-name"
                            role="button"
                            >Download sample csv file</label
                          >
                        </div>
                      </div>
                      <hr class="my-2" />
                    </div>
                  </div>
                  <div class="d-flex">
                    <span class="fa-stack ms-n1 me-3"
                      ><i class="fas fa-circle fa-stack-2x text-200"></i
                      ><i
                        class="fa-inverse fa-stack-1x text-primary fas fa-align-left"
                        data-fa-transform="shrink-2"
                      ></i
                    ></span>
                    <div class="flex-1">
                      <!-- <h5 class="mb-2 fs-0">KYCs</h5> -->
                      <div class="row">
                        <div class="mb-3 col-12">
                          <label class="col-form-label" for="recipient-name"
                            >Upload file</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="file"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                  <h6 class="mt-5 mt-lg-0">Other Actions</h6>
                  <ul class="nav flex-lg-column fs--1">
                    <li class="nav-item me-2 me-lg-0">
                      <a
                        @click="toggleShowOnMain"
                        class="nav-link nav-link-card-details"
                        role="button"
                        ><span class="fas fa-align-left me-2"></span>Import
                        history</a
                      >
                    </li>
                    <br />
                    <hr />
                    <li class="nav-item me-2 me-lg-0 mt-auto">
                      <a
                        class="btn btn-sm btn-falcon-warning rounded-pill"
                        role="button"
                        @click="saveResource"
                        ><span class="fa fa-upload me-2"></span>Upload
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End import modal  -->
  </div>
</template>

<script>
import mixins from "../mixins/index";
import CountUp from "vue-countup-v3";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import "flatpickr/dist/flatpickr.css";
import AppBar from "@/components/AppBar.vue";
import { useVuelidate } from "@vuelidate/core";
import flatPickr from "vue-flatpickr-component";
import EasyDataTable from "vue3-easy-data-table";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import { required, email } from "@vuelidate/validators";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import ClassService from "../services/class.service";
import Pagination from "v-pagination-3";

const now = new Date();

export default {
  mixins: [mixins],
  name: "ClassesView",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      formData: {
        name: { required },
        code: { required },
      },
    };
  },
  components: {
    NavDrawer,
    CountUp,
    AppBar,
    flatPickr,
    Pagination,
    FooterComp,
    LoaderOverlay,
    EasyDataTable,
  },
  data() {
    return {
      search_term: "",
      per_page: 18,
      records: 0,
      list_view: false,
      formData: {
        name: "",
        type: "",
        credits: "",
        category_id: "",
      },
      table_keys: 1,
      resources: [],
      categories: [],
      current_page: 1,
      searchValue: "",
      resources: [],
      orders_table_key: 0,
    };
  },
  created() {
    this.getClasses();
  },
  computed: {
    loading() {
      return this.$store.gtters.getLoading;
    },
    theme() {
      return localStorage.getItem("theme");
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    goToClass(item) {
      console.log(item);
      this.$router.push("/students-byclass/" + item.id);
    },
    myCallback(e) {
      this.getClassesPage(e);
    },
    addItem() {
      this.formData = {
        name: "",
        type: "",
      };
    },
    listView() {
      this.list_view = !this.list_view;
    },
    searchItem() {
      this.current_page = 1;
      this.page = 1;
      if (this.search_term) {
        this.$store.commit("SET_LOADING", true);
        ClassService.search(this.search_term).then(
          (response) => {
            this.records = response.data.data.total;
            this.resources = response.data.data.data;
            this.current_page = response.data.data.current_page;
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      } else {
        this.getClasses();
      }
    },
    goToAdmission(student) {
      this.$router.push("/new-admission/" + student.id);
    },
    goToNewAdmission() {
      this.$router.push("/new-admission");
    },
    getClasses() {
      this.$store.commit("SET_LOADING", true);
      ClassService.index().then(
        (response) => {
          this.records = response.data.data.total;
          this.resources = response.data.data.data;
          this.current_page = response.data.data.current_page;
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          console.log(error);
          toast.error({
            title: "Error",
            message: "Error getting user data",
          });
        }
      );
    },
    getClassesPage(page) {
      this.$store.commit("SET_LOADING", true);
      ClassService.byPage(page).then(
        (response) => {
          this.records = response.data.data.total;
          this.resources = response.data.data.data;
          this.current_page = response.data.data.current_page;
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          console.log(error);
          toast.error({
            title: "Error",
            message: "Error getting user data",
          });
        }
      );
    },
    editItem(item) {
      this.formData = item;
    },
    toggleActive() {
      this.formData.active = !this.formData.active;
    },
    toggleRoot() {
      this.formData.is_root = !this.formData.is_root;
    },
    saveResource() {
      this.v$.formData.name.$touch();
      if (this.v$.formData.$invalid) {
        return;
      }
      this.$store.commit("SET_LOADING", true);
      ClassService.store(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getClasses();
          } else {
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          toast.error(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    updateResource() {
      this.$store.commit("SET_LOADING", true);
      ClassService.update(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getClasses();
          } else {
            this.resources = [];
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    deleteResource(formData) {
      this.$store.commit("SET_LOADING", true);
      let sure = confirm(
        "Are you sure you want to delete? you cannot undo this action."
      );
      if (sure) {
        ClassService.delete(formData.id).then(
          (response) => {
            console.log(response);
            if (response.data.code == 200) {
              toast.success(response.data.message, {
                autoClose: 2000,
              });
              this.getClasses();
            } else {
              console.log(response.data.error);
              toast.error(response.data.error, {
                autoClose: 2000,
              });
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    },
  },
};
</script>
