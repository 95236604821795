<template>
  <div>
    <div class="card mb-3">
      <div class="card-body">
        <div class="row justify-content-between align-items-center">
          <div class="col-md">
            <h5 class="mb-2 mb-md-0">New Admission</h5>
          </div>
          <div class="col-auto">
            <button
              class="btn btn-falcon-warning btn-sm mb-2 mb-sm-0 me-2"
              type="button"
              @click="goToStudents"
            >
              <span class="fas fa-home" data-fa-transform=""></span
              ><span class="d-none d-sm-inline-block ms-1">
                Back to Students</span
              >
            </button>
            <button
              class="btn btn-falcon-warning btn-sm mb-2 mb-sm-0 me-2"
              type="button"
              @click="goToNewAdmission"
            >
              <span class="fas fa-user-plus" data-fa-transform=""></span
              ><span class="d-none d-sm-inline-block ms-1">Add Another</span>
            </button>
            <button
              class="btn btn-falcon-success btn-sm mb-2 mb-sm-0"
              type="button"
              @click="generateReport"
            >
              <span class="fas fa-arrow-down me-1"> </span>Download (.pdf)
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-3" id="element-to-print">
      <div class="card-body">
        <div class="row align-items-center text-center mb-0">
          <div class="col-sm-6 text-sm-start text-center">
            <img src="assets/img/EduSpace.png" alt="invoice" width="150" />
            <br />
            <img class="" :src="computedImageUrl" alt="invoice" height="150" />
          </div>
          <div class="col text-sm-end mt-3 mt-sm-0">
            <h5 class="text-danger">{{ user.institution.name }}</h5>
            <p class="fs--1 mb-0">
              {{ user.institution.address_line1 }}, <br />
              {{ user.institution.address_line2 }}, <br />
              {{ user.institution.address_line3 }}, <br />
              {{ user.institution.telephone }}
            </p>
            <h2 class="fs-bold" style="color: #f26a29">Admission Form</h2>
          </div>
        </div>
        <hr />
        <div class="align-items-center">
          <div
            class="d-flex text-white fw-bold p-2"
            style="background-color: #304787"
          >
            Student Information
            <span class="ms-auto">[*Required]</span>
          </div>
          <div class="col-auto">
            <div class="row">
              <div class="mb-3 col-sm-1 col-md-1 col-lg-1 col-xl-1">
                <label class="col-form-label" for="message-text">Auto ID</label>
                <input
                  class="form-control"
                  id="recipient-name"
                  type="text"
                  placeholder="e.g REG001"
                  v-model="formData.id"
                  disabled
                />
              </div>
              <div class="mb-3 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <label class="col-form-label" for="message-text"
                  >Registration No</label
                >
                <input
                  class="form-control"
                  id="recipient-name"
                  type="text"
                  placeholder="e.g REG001"
                  v-model="formData.admission_number"
                />
              </div>
              <div class="mb-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                <label class="col-form-label" for="recipient-name"
                  >First Name</label
                >
                <input
                  class="form-control"
                  id="recipient-name"
                  type="text"
                  placeholder="e.g John"
                  v-model="formData.first_name"
                  @change="updateStudent"
                  :class="
                    v$.formData.first_name.$dirty &&
                    v$.formData.first_name.$invalid
                      ? `is-invalid`
                      : v$.formData.first_name.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.first_name.$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <div class="mb-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                <label class="col-form-label" for="recipient-name"
                  >Last Name</label
                >
                <input
                  class="form-control"
                  id="recipient-name"
                  type="text"
                  placeholder="e.g Doe"
                  @change="updateStudent"
                  v-model="formData.last_name"
                  :class="
                    v$.formData.last_name.$dirty &&
                    v$.formData.last_name.$invalid
                      ? `is-invalid`
                      : v$.formData.last_name.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.last_name.$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <div class="mb-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                <label class="col-form-label" for="message-text"
                  >Mobile No</label
                >
                <input
                  class="form-control"
                  id="recipient-name"
                  type="text"
                  placeholder="e.g 0728455655"
                  v-model="formData.phone"
                  @change="updateStudent"
                />
              </div>
            </div>
            <div class="row">
              <div class="mb-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                <label class="col-form-label" for="message-text"
                  >County</label
                >
                <input
                  class="form-control"
                  id="recipient-name"
                  type="text"
                  placeholder="e.g Nairobi"
                  v-model="formData.county"
                  @change="updateStudent"
                />
              </div>
              <div class="mb-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                <label class="col-form-label" for="message-text"
                  >Town of Residence</label
                >
                <input
                  class="form-control"
                  id="recipient-name"
                  type="text"
                  placeholder="e.g Nairobi"
                  v-model="formData.town"
                  @change="updateStudent"
                />
              </div>
              <div class="mb-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                <label class="col-form-label" for="message-text"
                  >Postal Address</label
                >
                <input
                  class="form-control"
                  id="recipient-name"
                  type="text"
                  placeholder="e.g P.O Box 1254, Nairobi"
                  v-model="formData.postal_address"
                  @change="updateStudent"
                />
              </div>
              <div class="mb-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                <label class="col-form-label" for="message-text"
                  >Date of Birth</label
                >
                <div class="input-group mb-3">
                  <Datepicker
                    class="form-control"
                    placeholder="Pick date"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    v-model="formData.dob"
                    @change="updateStudent"
                  />
                  <span class="input-group-text" id="basic-addon1">
                    <i class="fa fa-calendar"></i>
                  </span>
                </div>
              </div>
            </div>
            <!-- For college  -->
            <div v-if="institution.type == 'College'" class="row">
              <div class="mb-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                <label class="col-form-label" for="message-text"
                  >Passport Photo [Max 1MB]</label
                >
                <input
                  @change="uploadPhoto"
                  class="form-control"
                  id="file"
                  name="file"
                  type="file"
                />
              </div>
              <div class="mb-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                <label class="col-form-label" for="message-text">ID No</label>
                <input
                  class="form-control"
                  id="recipient-name"
                  type="text"
                  placeholder="e.g 32001415"
                  v-model="formData.id_number"
                  @change="updateStudent"
                />
              </div>
              <div class="mb-3 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <label class="col-form-label" for="message-text">Class</label>
                <select
                  class="form-select"
                  aria-label="Select"
                  v-model="formData.form_id"
                  @change="updateStudent"
                >
                  <option value="">Select...</option>
                  <option
                    :value="el.id"
                    v-for="(el, i) in classes"
                    :key="i"
                    :selected="formData.form_id == el.id"
                  >
                    {{ el.name }}
                  </option>
                </select>
              </div>
              <div class="mb-3 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <label class="col-form-label" for="message-text"
                  >Marital Status</label
                >
                <select
                  class="form-select"
                  aria-label="Select"
                  v-model="formData.marital_status"
                  @change="updateStudent"
                >
                  <option value="">Select...</option>
                  <option value="married">Married</option>
                  <option value="unmarried">Unmarried</option>
                  <option value="engaged">Engaged</option>
                  <option value="divorced">Divorced</option>
                </select>
              </div>
              <div class="mb-3 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <label class="col-form-label" for="message-text">Gender</label>
                <select
                  class="form-select"
                  aria-label="Select"
                  v-model="formData.gender"
                  @change="updateStudent"
                >
                  <option value="">Select...</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
            </div>
          </div>

          <div
            v-if="institution.type == 'College'"
            class="d-flex text-white fw-bold p-2"
            style="background-color: #445993"
          >
            Family Information
            <span class="ms-auto">[Optional]</span>
          </div>
          <div v-if="institution.type == 'College'" class="col-auto">
            <div class="row">
              <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <label class="col-form-label" for="message-text"
                  >Spause Name</label
                >
                <input
                  class="form-control"
                  id="recipient-name"
                  type="text"
                  placeholder="e.g 1"
                  @change="updateStudent"
                  v-model="formData.spause_name"
                />
              </div>
              <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <label class="col-form-label" for="message-text"
                  >Date of Birth</label
                >
                <input
                  class="form-control"
                  id="recipient-name"
                  type="text"
                  placeholder="e.g UPI/32135456"
                  v-model="formData.spause_dob"
                  @change="updateStudent"
                />
              </div>
              <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <label class="col-form-label" for="recipient-name"
                  >Tel No</label
                >
                <input
                  class="form-control"
                  id="recipient-name"
                  type="text"
                  placeholder="e.g 077676757"
                  v-model="formData.spause_phone"
                  @change="updateStudent"
                />
              </div>
              <div class="mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <label class="col-form-label" for="recipient-name"
                  >Children & Date of Birth</label
                >
                <textarea
                  class="form-control"
                  id="recipient-name"
                  type="text"
                  placeholder="e.g John Doe 2000-11-20, Marry Wilsons 2002-12-31"
                  v-model="formData.children_details"
                  @change="updateStudent"
                />
              </div>
            </div>
          </div>
          <div
            class="d-flex text-white fw-bold p-2"
            style="background-color: #596b9f"
          >
            Academic Information
            <span class="ms-auto">[Optional]</span>
          </div>
          <div class="col-auto">
            <div class="row">
              <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <label class="col-form-label" for="message-text"
                  >University/College</label
                >
                <input
                  class="form-control"
                  id="recipient-name"
                  type="text"
                  placeholder="Name"
                  v-model="formData.college_name"
                  @change="updateStudent"
                />
                <input
                  class="form-control mt-1"
                  id="recipient-name"
                  type="text"
                  placeholder="Year"
                  v-model="formData.college_year"
                  @change="updateStudent"
                />
                <input
                  class="form-control mt-1"
                  id="recipient-name"
                  type="text"
                  placeholder="Grade/Points"
                  v-model="formData.college_grade"
                  @change="updateStudent"
                />
              </div>
              <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <label class="col-form-label" for="message-text"
                  >High School</label
                >
                <input
                  class="form-control"
                  id="recipient-name"
                  type="text"
                  placeholder="Name"
                  v-model="formData.high_school_name"
                  @change="updateStudent"
                />
                <input
                  class="form-control mt-1"
                  id="recipient-name"
                  type="text"
                  placeholder="Year"
                  v-model="formData.high_school_year"
                  @change="updateStudent"
                />
                <input
                  class="form-control mt-1"
                  id="recipient-name"
                  type="text"
                  placeholder="Grade/Points"
                  v-model="formData.high_school_grade"
                  @change="updateStudent"
                />
              </div>
              <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <label class="col-form-label" for="message-text"
                  >Primary School</label
                >
                <input
                  class="form-control"
                  id="recipient-name"
                  type="text"
                  placeholder="Name"
                  v-model="formData.primary_school_name"
                  @change="updateStudent"
                />
                <input
                  class="form-control mt-1"
                  id="recipient-name"
                  type="text"
                  placeholder="Year"
                  v-model="formData.primary_school_year"
                  @change="updateStudent"
                />
                <input
                  class="form-control mt-1"
                  id="recipient-name"
                  type="text"
                  placeholder="Grade/Points"
                  v-model="formData.primary_school_grade"
                  @change="updateStudent"
                />
              </div>
            </div>
            <div class="row">
              <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <label class="col-form-label" for="message-text"
                  >Mother tongue</label
                >
                <input
                  class="form-control"
                  id="recipient-name"
                  type="text"
                  v-model="formData.mother_tongue"
                  @change="updateStudent"
                />
                <label class="col-form-label" for="message-text"
                  >Other Languages</label
                >
                <input
                  class="form-control"
                  id="recipient-name"
                  type="text"
                  v-model="formData.other_language"
                  @change="updateStudent"
                />
              </div>
              <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <label class="col-form-label" for="message-text"
                  >Any previous theological education?</label
                >
                <select
                  class="form-select"
                  aria-label="Select gender"
                  v-model="formData.previous_training"
                  @change="updateStudent"
                >
                  <option value="">Select option...</option>
                  <option value="No">No</option>
                  <option value="yes">Yes</option>
                </select>
                <textarea
                  class="form-control mt-1"
                  id="recipient-name"
                  type="text"
                  placeholder="If yes, specify"
                  v-model="formData.previous_taining_details"
                  @change="updateStudent"
                />
              </div>
              <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <label class="col-form-label" for="message-text"
                  >Attach supportive document (.pdf) <br />[Max 1MB]</label
                >
                <input class="form-control" id="recipient-home" type="file" />
              </div>
            </div>
          </div>
        </div>
        <div class="align-items-center">
          <div
            class="d-flex text-white fw-bold p-2"
            style="background-color: #6e7eab"
          >
            Christian Experience
            <span class="ms-auto">[*Required]</span>
          </div>
        </div>

        <div v-if="institution.type == 'College'" class="row">
          <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
            <label class="col-form-label" for="message-text"
              >Recieved Christ as your personal savior?</label
            >
            <select
              class="form-select"
              aria-label="Select gender"
              v-model="formData.recieved_christ"
              @change="updateStudent"
            >
              <option value="">Select option...</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
            <input
              class="form-control mt-1"
              id="recipient-name"
              type="text"
              placeholder="If Yes, when?"
              v-model="formData.recieved_christ_when"
              @change="updateStudent"
            />
          </div>

          <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
            <label class="col-form-label" for="message-text">Testimony</label>
            <textarea
              class="form-control"
              id="recipient-name"
              type="text"
              placeholder="Briefly write down your testimony"
              v-model="formData.testimony"
              @change="updateStudent"
            />
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
            <label class="col-form-label" for="message-text"
              >Recieved believer's baptism?</label
            >
            <select
              class="form-select"
              aria-label="Select gender"
              v-model="formData.believers_baptism"
              @change="updateStudent"
            >
              <option value="">Select gender...</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
            <textarea
              class="form-control mt-1"
              id="recipient-name"
              type="text"
              placeholder="If so, when and how?"
              v-model="formData.believers_baptism_when_and_how"
              @change="updateStudent"
            />
          </div>
          <div class="mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <label class="col-form-label" for="message-text"
              >Involvement in Christian Ministry?</label
            >
            <textarea
              class="form-control mt-1"
              id="recipient-name"
              type="text"
              rows="5"
              placeholder="Briefly explain your involvement in christian ministry"
              v-model="formData.believers_baptism_when_and_how"
              @change="updateStudent"
            />
          </div>
        </div>

        <div class="align-items-center">
          <div
            class="d-flex text-white fw-bold p-2"
            style="background-color: #8290b7"
          >
            Church Affiliation
            <span class="ms-auto">[*Required]</span>
          </div>
        </div>

        <div class="row">
          <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <label class="col-form-label" for="message-text"
              >Which church is recommending you?</label
            >
            <textarea
              class="form-control mt-1"
              id="recipient-name"
              type="text"
              placeholder="Name and Location"
              v-model="formData.recommending_church"
              @change="updateStudent"
            />
          </div>

          <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <label class="col-form-label" for="message-text"
              >Which churches have you attended to?</label
            >
            <textarea
              class="form-control"
              id="recipient-name"
              type="text"
              placeholder="Which churches have you attended to and for how long?"
              v-model="formData.church_attended"
              @change="updateStudent"
            />
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <label class="col-form-label" for="message-text"
              >Ever been under church disciplines?</label
            >
            <select
              class="form-select"
              aria-label="Select gender"
              v-model="formData.disciplines"
            >
              <option value="">Select gender...</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
            <textarea
              class="form-control mt-1"
              id="recipient-name"
              type="text"
              placeholder="If yes, give reasons?"
              v-model="formData.disciplines_reasons"
            />
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <label class="col-form-label" for="message-text"
              >Ever served any leadership role?</label
            >
            <select
              class="form-select"
              aria-label="Select gender"
              v-model="formData.previous_role"
              @change="updateStudent"
            >
              <option value="">Select gender...</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
            <textarea
              class="form-control mt-1"
              id="recipient-name"
              type="text"
              placeholder="If so, explain?"
              v-model="formData.previous_role_details"
              @change="updateStudent"
            />
          </div>
          <div class="mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <label class="col-form-label" for="message-text"
              >Involvement in Christian Ministry?</label
            >
            <textarea
              class="form-control mt-1"
              id="recipient-name"
              type="text"
              rows="5"
              placeholder="Briefly explain your involvement in christian ministry"
              v-model="formData.believers_baptism_when_and_how"
              @change="updateStudent"
            />
          </div>

          <div class="align-items-center">
            <div
              class="d-flex text-white fw-bold p-2"
              style="background-color: #97a3c3"
            >
              References
              <span class="ms-auto"></span>
            </div>
          </div>

          <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
            <label class="col-form-label" for="message-text"
              >Pastor's Reference</label
            >
            <input
              class="form-control"
              id="recipient-name"
              type="text"
              placeholder="Name"
              v-model="formData.pastor_reference"
              @change="updateStudent"
            />
            <input
              class="form-control mt-1"
              id="recipient-name"
              type="text"
              placeholder="Phone"
              v-model="formData.pastor_reference_phone"
              @change="updateStudent"
            />
            <input
              class="form-control mt-1"
              id="recipient-name"
              type="text"
              placeholder="ID No"
              v-model="formData.pastor_reference_id_no"
              @change="updateStudent"
            />
            <input
              class="form-control mt-1"
              id="recipient-name"
              type="text"
              placeholder="Email"
              v-model="formData.pastor_reference_email"
              @change="updateStudent"
            />
            <input
              class="form-control mt-1"
              id="recipient-name"
              type="text"
              placeholder="Place of Residence"
              v-model="formData.pastor_reference_residence"
              @change="updateStudent"
            />
          </div>
          <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
            <label class="col-form-label" for="message-text"
              >General Reference 1</label
            >
            <input
              class="form-control"
              id="recipient-name"
              type="text"
              placeholder="Name"
              v-model="formData.pastor_reference"
              @change="updateStudent"
            />
            <input
              class="form-control mt-1"
              id="recipient-name"
              type="text"
              placeholder="Phone"
              v-model="formData.pastor_reference_phone"
              @change="updateStudent"
            />
            <input
              class="form-control mt-1"
              id="recipient-name"
              type="text"
              placeholder="ID No"
              v-model="formData.pastor_reference_id_no"
              @change="updateStudent"
            />
            <input
              class="form-control mt-1"
              id="recipient-name"
              type="text"
              placeholder="Email"
              v-model="formData.pastor_reference_email"
              @change="updateStudent"
            />
            <input
              class="form-control mt-1"
              id="recipient-name"
              type="text"
              placeholder="Place of Residence"
              v-model="formData.pastor_reference_residence"
              @change="updateStudent"
            />
          </div>
          <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
            <label class="col-form-label" for="message-text"
              >General Reference 2</label
            >
            <input
              class="form-control"
              id="recipient-name"
              type="text"
              placeholder="Name"
              v-model="formData.pastor_reference"
              @change="updateStudent"
            />
            <input
              class="form-control mt-1"
              id="recipient-name"
              type="text"
              placeholder="Phone"
              v-model="formData.pastor_reference_phone"
              @change="updateStudent"
            />
            <input
              class="form-control mt-1"
              id="recipient-name"
              type="text"
              placeholder="ID No"
              v-model="formData.pastor_reference_id_no"
              @change="updateStudent"
            />
            <input
              class="form-control mt-1"
              id="recipient-name"
              type="text"
              placeholder="Email"
              v-model="formData.pastor_reference_email"
              @change="updateStudent"
            />
            <input
              class="form-control mt-1"
              id="recipient-name"
              type="text"
              placeholder="Place of Residence"
              v-model="formData.pastor_reference_residence"
              @change="updateStudent"
            />
          </div>
        </div>
        <hr />
        <div class="row justify-content-en mt-3">
          <div class="col-auto">
            Prepared by: <b>{{ user.first_name }} {{ user.last_name }}</b>
            <br />
            <span
              ><u>User Role: {{ user.role_name }}</u></span
            >
            <br />
            <span class="text-muted">Approved by:</span>
            <br />
            <br />
            <span class="text-primary">Joh Doe: </span>
            __________________
          </div>
          <div class="col-auto ms-auto">
            <table class="table table-sm table-borderless fs--1 text-end">
              <tr>
                <th class="text-900 border" colspan="2">Payment Details</th>
              </tr>
              <tr>
                <th class="text-900 border">M-PESA PAYBILL NO:</th>
                <td class="fw-semi-bold border">503200</td>
              </tr>
              <tr class="border-top">
                <th class="text-900 border">Account Name::</th>
                <td class="fw-semi-bold border">MKBBC</td>
              </tr>
              <tr class="border-top border-top-2 fw-bolder text-900">
                <th class="border">Amount:</th>
                <td class="border">KES 2,500.00</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="card-footer bg-light">
        <p class="fs--1 mb-0 bt-n4">
          <strong>Declaration: </strong>
          I solemnly declare that all the above information is accurate and true
          to the best of my knowledge.
        </p>
      </div>
    </div>

    <div
      class="modal fade"
      id="authentication-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="authentication-modal-label"
      aria-hidden="true"
    >
      <div class="modal-dialog mt-6" role="document">
        <div class="modal-content border-0">
          <div
            class="modal-header px-5 position-relative modal-shape-header bg-shape"
          >
            <div class="position-relative z-index-1 light">
              <h4 class="mb-0 text-white" id="authentication-modal-label">
                Register
              </h4>
              <p class="fs--1 mb-0 text-white">
                Please create your free Falcon account
              </p>
            </div>
            <button
              class="btn-close btn-close-white position-absolute top-0 end-0 mt-2 me-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body py-4 px-5">
            <form>
              <div class="mb-3">
                <label class="form-label" for="modal-auth-name">Name</label
                ><input
                  class="form-control"
                  type="text"
                  autocomplete="on"
                  id="modal-auth-name"
                />
              </div>
              <div class="mb-3">
                <label class="form-label" for="modal-auth-email"
                  >Email address</label
                ><input
                  class="form-control"
                  type="email"
                  autocomplete="on"
                  id="modal-auth-email"
                />
              </div>
              <div class="row gx-2">
                <div class="mb-3 col-sm-6">
                  <label class="form-label" for="modal-auth-password"
                    >Password</label
                  ><input
                    class="form-control"
                    type="password"
                    autocomplete="on"
                    id="modal-auth-password"
                  />
                </div>
                <div class="mb-3 col-sm-6">
                  <label class="form-label" for="modal-auth-confirm-password"
                    >Confirm Password</label
                  ><input
                    class="form-control"
                    type="password"
                    autocomplete="on"
                    id="modal-auth-confirm-password"
                  />
                </div>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="modal-auth-register-checkbox"
                /><label class="form-label" for="modal-auth-register-checkbox"
                  >I accept the <a href="index.html#!">terms </a>and
                  <a href="index.html#!">privacy policy</a></label
                >
              </div>
              <div class="mb-3">
                <button
                  class="btn btn-primary d-block w-100 mt-3"
                  type="submit"
                  name="submit"
                >
                  Register
                </button>
              </div>
            </form>
            <div class="position-relative mt-5">
              <hr />
              <div class="divider-content-center">or register with</div>
            </div>
            <div class="row g-2 mt-2">
              <div class="col-sm-6">
                <a
                  class="btn btn-outline-google-plus btn-sm d-block w-100"
                  href="index.html#"
                  ><span
                    class="fab fa-google-plus-g me-2"
                    data-fa-transform="grow-8"
                  ></span>
                  google</a
                >
              </div>
              <div class="col-sm-6">
                <a
                  class="btn btn-outline-facebook btn-sm d-block w-100"
                  href="index.html#"
                  ><span
                    class="fab fa-facebook-square me-2"
                    data-fa-transform="grow-8"
                  ></span>
                  facebook</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-background-color: #edf2f9;
  --easy-table-header-item-padding: 10px 15px;
}
</style>

<script>
import mixins from "../mixins/index";
import CountUp from "vue-countup-v3";
import Datepicker from "vue3-datepicker";
import AppBar from "@/components/AppBar.vue";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import ReportService from "../services/report.service";
import StudentService from "../services/student.service";
import ClassService from "../services/class.service";
import EasyDataTable from "vue3-easy-data-table";
import SimpleTypeahead from "vue3-simple-typeahead";
import { useVuelidate } from "@vuelidate/core";
import "vue3-simple-typeahead/dist/vue3-simple-typeahead.css";
import { required, email, minLength } from "@vuelidate/validators";

export default {
  mixins: [mixins],
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    AppBar,
    CountUp,
    Datepicker,
    NavDrawer,
    FooterComp,
    EasyDataTable,
    SimpleTypeahead,
  },
  data() {
    return {
      classes: [],
      formData: {
        active: true,
        email: "",
        gender: "",
        form_id: "",
        last_name: "",
        first_name: "",
        marital_status: "",
      },
    };
  },
  created() {
    this.getForms();
    let student_id = this.$route.params.id;
    if (this.formData.id || student_id) {
      if (student_id) {
        this.formData.id = student_id;
      }
      this.getStudent();
    } else {
      this.createStudent();
    }
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
    institution() {
      return JSON.parse(localStorage.getItem("institution"));
    },
    computedImageUrl() {
      return this.formData.image
        ? process.env.VUE_APP_API_URL + this.formData.image
        : "assets/img/team/default.png";
    },
  },
  watch: {},
  validations() {
    return {
      formData: {
        first_name: { required },
        email: { required },
        last_name: { required },
        car_model: { required },
        car_value: { required },
        customer_name: { required },
        email: { required, email },
        phone: { required, minLength: minLength(8) },
      },
    };
  },
  methods: {
    uploadPhoto(e) {
      console.log(e.target.files[0]);
      this.formData.file = e.target.files[0];
      let formData = new FormData();
      formData.append("file", this.formData.file);
      formData.append("id", this.formData.id);
      // custom for uploads
      let token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      };
      return StudentService.upload(formData, config).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            this.formData.image = response.data.data.image;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getForms() {
      ClassService.all().then(
        (response) => {
          console.log("response", response);
          this.classes = response.data.data;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    goToNewAdmission() {
      this.createStudent();
    },
    generateReport() {
      var element = document.getElementById("element-to-print");
      html2pdf(element, {
        margin: 0.1,
        filename: "JIA Quotation" + ".pdf",
        pagebreak: { after: ".sautDePage" },
        image: {
          type: "jpeg",
          quality: 2,
        },
        html2canvas: {
          scale: 2,
          letterRendering: true,
        },
        jsPDF: {
          unit: "in",
          format: "a4",
          orientation: "portrait",
        },
      });
    },
    goToCustomers() {},
    goToStudents() {
      this.$router.push("/students");
    },
    getTimegreetings() {
      const time = new Date().getHours();
      let greetings;
      if (time < 12) greetings = "Good Morning";
      else if (time >= 12 && time <= 17) greetings = "Good Afternoon";
      else greetings = "Good Evening";
      return greetings;
    },
    formatToCurrency(amount) {
      if (amount || amount == 0) {
        const internationalNumberFormat = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "KES",
        });
        return internationalNumberFormat.format(amount);
      } else {
        return amount;
      }
    },
    getStudent() {
      this.$store.commit("SET_LOADING", true);
      return StudentService.show(this.formData.id).then(
        (response) => {
          if (response.data.code == 200) {
            this.formData = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    createStudent() {
      this.$store.commit("SET_LOADING", true);
      return StudentService.store(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.formData = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    updateStudent() {
      console.log(this.formData);
      // this.$store.commit("SET_LOADING", true);
      return StudentService.update(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.formData = response.data.data;
          } else {
            console.log(response.data.error);
          }
          // this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getDailyAnalysis() {
      this.$store.commit("SET_LOADING", true);
      return ReportService.dailyAnalysis().then(
        (response) => {
          if (response.data.code == 200) {
            this.daily_analysis = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getWeeklyAnalysis() {
      this.$store.commit("SET_LOADING", true);
      return ReportService.weeklyAnalysis().then(
        (response) => {
          if (response.data.code == 200) {
            this.weekly_analysis = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
