import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueTelInput from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import Vue3Tour from 'vue3-tour'
import 'vue3-tour/dist/vue3-tour.css'
import Main from './views/Layout/Main';
import Blank from './views/Layout/Blank';
import VueFeather from 'vue-feather';
import Default from './views/Layout/Default';

const VueTelInputOptions = {
  mode: "international",
  placeholder: "Phone Number *",
  onlyCountries: ["KE", "UG", "TZ"],
};


import { Vue3Mq } from "vue3-mq";

createApp(App)
.use(store)
.use(router)
.use(Vue3Mq)
.use(Vue3Tour)
.component('main-layout', Main)
.component('blank-layout', Blank)
.component('vue-feather', VueFeather)
.component('default-layout', Default)
.use(VueTelInput, VueTelInputOptions)
.use('EasyDataTable', Vue3EasyDataTable)
  .mount("#app");
